








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';

import departments from '@/utils/filters/departments';
import { Exam } from '@/store/models/Exam';
import UserUnavailability from '@/components/UserUnavailability.vue';
import UserWithLevel from '@/components/UserWithLevel.vue';
import CompetenceName from '@/components/CompetenceName.vue';

@Component({
  components: { CompetenceName, UserUnavailability, UserWithLevel },
  filters: {
    departments,
  },
})
export default class CurrentExamListItem extends Vue {
  private blockName: string = 'current-exam-list-item';

  @Prop()
  private exam!: Exam

  @Prop({ default: true })
  private editable: boolean;

  @Prop({ default: true })
  private showDepartment: boolean;

  @Prop({ default: false })
  private isNotAssigned: boolean;

  private get frame(): string {
    if (this.exam.frames[0] != null) {
      const frame = DateTime.fromISO(this.exam.frames[0].frame);
      return frame.toFormat("dd.LL.yyyy 'в' HH:mm");
    }
    return null;
  }

  private get examiner(): string {
    const { examiner } = this.exam;
    if (examiner != null) {
      return `${examiner.firstName} ${examiner.lastName}`;
    }
    return null;
  }

  private get showStatus() {
    return ['sick', 'rejected', 'timeOver', 'passed', 'failed'].includes(this.exam.status);
  }
}
