















import { Component, Vue } from 'vue-property-decorator';

import WaitingPageThankYouText from '@/components/graph-elements/WaitingPageThankYouText.vue';

@Component({
  components: { WaitingPageThankYouText },
})
export default class Waiting extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'waiting-page';
}
