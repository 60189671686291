import {
  Module, VuexModule, Action, Mutation,
} from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { BindingHelpers } from 'vuex-class/lib/bindings';

import adapter from '@/store/adapter';
import Logger from '@/utils/Logger';
import { Department } from '@/store/models/Departments';

export const MODULE_NAME = 'departments';
export const getDepartmentsNamespace = (): BindingHelpers => namespace(MODULE_NAME);

@Module({ stateFactory: true, namespaced: true })
export default class DepartmentsModule extends VuexModule {
  private static _namespace: BindingHelpers = null;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getDepartmentsNamespace();

    return this._namespace;
  }

  public departments: Department[] = [];

  @Mutation
  public setDepartments(departments: Department[]): void {
    this.departments = departments;
  }

  @Action
  public async getDepartments(): Promise<Department[]> {
    try {
      const response = await adapter.get<Department[]>('/departments', {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      this.context.commit('setDepartments', response.data);
      return response.data;
    } catch (error) {
      Logger.error('Store::DepartmentsModule:getDepartments', error);
      return null;
    }
  }
}
