



























import { Vue, Component, Prop } from 'vue-property-decorator';

import { ConfigModule } from '@/store/modules';

@Component({
  components: { },
})
export default class Template extends Vue {
  @Prop({ default: () => ({}) }) private mods!: Modificator;

  @Prop({ default: 'Отмена' }) cancelButtonText!: string;

  @Prop({ default: 'Подтвердить' }) confirmButtonText!: string;

  @Prop() content: string;

  @ConfigModule.namespace.State('isMobile')
  isMobile: ConfigModule['isMobile'];

  private blockName: string = 'confirm-modal';
}
