import { render, staticRenderFns } from "./CurrentExamListItem.vue?vue&type=template&id=946edd50&scoped=true&"
import script from "./CurrentExamListItem.vue?vue&type=script&lang=ts&"
export * from "./CurrentExamListItem.vue?vue&type=script&lang=ts&"
import style0 from "./CurrentExamListItem.vue?vue&type=style&index=0&id=946edd50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "946edd50",
  null
  
)

export default component.exports