











































import { Vue, Component, Prop } from 'vue-property-decorator';

import { Exam } from '@/store/models/Exam';
import { User } from '@/store/models/User';
import UserWithLevel from '@/components/UserWithLevel.vue';
import CompetenceName from '@/components/CompetenceName.vue';
import { examsResults, ExamStatus, ExamStatusEnum } from '@/store/enums';
import { AppSelectOption } from '@/components/core/AppSelect.vue';

const statusesOptions: AppSelectOption<ExamStatus>[] = examsResults.map((status) => ({
  label: ExamStatusEnum[status],
  // eslint-disable-next-line global-require,import/no-dynamic-require
  icon: require(`@/assets/${status}.svg`),
  value: status,
}));

@Component({
  components: { UserWithLevel, CompetenceName },
})
export default class ExamInfo extends Vue {
  private blockName: string = 'exam-info';

  @Prop()
  private exam: Exam;

  @Prop()
  private user: User;

  @Prop()
  private examiner: Pick<User, 'firstName' | 'lastName' | 'id'>;

  private onCancel() {
    this.$modal.hide(this.blockName);
  }

  private get examStatus(): AppSelectOption<ExamStatus> {
    return statusesOptions.find((item) => item.value === this.exam.status);
  }
}
