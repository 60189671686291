import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { BindingHelpers } from 'vuex-class/lib/bindings';

import config from '@/configs/config';

export const MODULE_NAME = 'config';
export const getConfigNamespace = (): BindingHelpers => namespace(MODULE_NAME);

const isMobileMatcher: MediaQueryList = matchMedia('only screen and (max-width: 500px)');

@Module({ stateFactory: true, namespaced: true })
export default class ConfigModule extends VuexModule {
  private static _namespace: BindingHelpers = null;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getConfigNamespace();

    return this._namespace;
  }

  public headerIsVisible: boolean = true;

  public isProfileBlockVisible: boolean = true;

  public appName: string = 'Dunice Rise Up';

  public apiHostUrl: string = config.host;

  public ssoHostUri: string = config.ssoHost;

  public isMobile: boolean = isMobileMatcher.matches;

  @Mutation
  public switchOffHeader(): void {
    this.headerIsVisible = false;
  }

  @Mutation
  public switchOnHeader(): void {
    this.headerIsVisible = true;
  }

  @Mutation
  public switchOffProfileBlock(): void {
    this.isProfileBlockVisible = false;
  }

  @Mutation
  public switchOnProfileBlock(): void {
    this.isProfileBlockVisible = true;
  }

  @Mutation
  public updateIsMobile(value: boolean): void {
    this.isMobile = value;
  }

  @Action
  public activateIsMobileWatcher(): void {
    this.context.commit('updateIsMobile', isMobileMatcher.matches);
    isMobileMatcher.addListener((event: MediaQueryListEvent) => {
      this.context.commit('updateIsMobile', (event.target as MediaQueryList).matches);
    });
  }
}
