







































import { Component, Vue, Prop } from 'vue-property-decorator';

import { RiseUpLevel, RiseUpLevelEnum } from '@/store/enums';

@Component({
  components: {},
  filters: {},
})
export default class LevelTabs extends Vue {
  private blockName: string = 'level-tab-bar';

  @Prop({ default: () => ({}) }) private mods!: Modificator;

  @Prop({ default: RiseUpLevelEnum.JUNIOR })
  private current!: RiseUpLevel;

  private changeLevel(level: RiseUpLevel) {
    this.$emit('changed', level);
  }
}
