






















































import { Component, Vue } from 'vue-property-decorator';

import WeekSelect from '@/components/WeekSelect.vue';
import { ExamModule, ExaminersModule } from '@/store/modules';
import AppChip from '@/components/core/AppChip.vue';

@Component({
  components: { WeekSelect, Chip: AppChip },
})
export default class ExamBusyness extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'exams-busyness';

  @ExamModule.namespace.State('currentExamsFilter')
  private currentExamsFilter: ExamModule['currentExamsFilter'];

  @ExamModule.namespace.Mutation('updateCurrentExamsWeekNumberFilter')
  private updateCurrentExamsWeekNumberFilter: ExamModule['updateCurrentExamsWeekNumberFilter'];

  @ExaminersModule.namespace.Action('getExaminersBusyness')
  private getExaminersBusyness: ExaminersModule['getExaminersBusyness'];

  @ExaminersModule.namespace.State('examinersBusyness')
  private examinersBusyness: ExaminersModule['examinersBusyness'];

  created(): void {
    this.retrieveBusyness();
  }

  private get weekNumber(): string {
    return this.currentExamsFilter.weekNumber;
  }

  private set weekNumber(week) {
    this.updateCurrentExamsWeekNumberFilter(week);
    this.retrieveBusyness();
    this.$router.push({
      name: 'ExaminersBusyness',
      query: { weekNumber: week ?? 'All' },
    });
  }

  private retrieveBusyness(): void {
    this.getExaminersBusyness(this.weekNumber);
  }
}
