












































import { Vue, Component, Prop } from 'vue-property-decorator';

import { Competence } from '@/store/models/Competence';
import { ConfigModule } from '@/store/modules';
import CompetenceName from '@/components/CompetenceName.vue';

@Component({
  components: { CompetenceName },
})
export default class CompetenceCard extends Vue {
  @Prop({ default: () => ({}) }) private mods!: Modificator;

  @Prop() private competence!: Competence;

  @Prop({ default: () => false }) private isLast!: boolean;

  @Prop({ default: () => false }) private isAllowed!: boolean;

  @Prop({ default: () => '' }) private specializationName!: string;

  @Prop({ default: () => false }) private specializationIsCompleted!: boolean;

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  private blockName: string = 'competence-card';

  private hasShield: boolean = true;

  private get state() {
    if (this.competence.passed) return 'passed';
    if (!this.isAllowed) return 'not-allowed';
    if (this.competence.hasRegisteredExam) return 'has-unfinished-exam';
    return '';
  }

  getSheildUrl(specializationName: string, specializationIsCompleted: boolean): string | null {
    try {
      this.hasShield = true;
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/shields/${specializationName[0]}_${specializationIsCompleted ? 'on' : 'off'}.svg`);
    } catch (error) {
      this.hasShield = false;
      return null;
    }
  }
}
