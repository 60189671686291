import Vue from 'vue';

import bemElement from './bem-element';
import bemMods from './bem-mods';
import duration from './duration';
import fileSize from './file-size';
import levelName from './level-name';
import roleName from './role-name';
import replace from './replace';
import clearTags from './clear-tags';
import integer from './integer';
import userSpecializations from './user-specializations';
import directionName from './direction-name';

Vue.filter('bemElement', bemElement);
Vue.filter('bemMods', bemMods);
Vue.filter('duration', duration);
Vue.filter('fileSize', fileSize);
Vue.filter('levelName', levelName);
Vue.filter('roleName', roleName);
Vue.filter('clearTags', clearTags);
Vue.filter('parseInt', integer);
Vue.filter('userSpecializations', userSpecializations);
Vue.filter('directionName', directionName);

export default { replace };
