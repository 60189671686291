



























import { Vue, Component, Prop } from 'vue-property-decorator';

import { Specialization } from '@/store/models/Specialization';
import { Competence } from '@/store/models/Competence';

import CompetenceCard from '@/components/CompetenceCard.vue';

import mround from '@/utils/filters/mround';

@Component({
  components: { CompetenceCard },
  filters: { mround },
})
export default class SpecializationLine extends Vue {
  @Prop({ default: () => ({}) }) private mods!: Modificator;

  @Prop() private specialization!: Specialization;

  private blockName: string = 'specialization-line';

  private competenceIsAllowed(competence: Competence, index: number) {
    if (competence.passed) return true;
    if (index === 0) return true;
    const prevCompetence = this.specialization.competencies[index - 1];
    if (index > 0 && prevCompetence.passed) return true;
    if (index === 1 && prevCompetence.duration === 20 && competence.duration === 20) return true;
    const secondBeforeCurrentCompetence = this.specialization.competencies[index - 2];
    return (
      index > 1
      && secondBeforeCurrentCompetence.passed
      && prevCompetence.duration === 20
      && competence.duration === 20
    );
  }

  private competenceClicked(competence: Competence, competenceIsAllowed: boolean): void {
    this.$emit('competenceClicked', competence, competenceIsAllowed);
  }
}
