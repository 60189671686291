
















































































import { Component, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';

import { ExaminersModule } from '@/store/modules';
import Select from '@/components/Select/Select.vue';
import { MonthExamStatistic } from '@/store/models/Examiners';

interface YearSelectOption {
  id: number;
  label: string;
  header: boolean;
}

@Component({
  components: { Select },
})
export default class ExaminersExamsCountStatistic extends Vue {
  private blockName: string = 'examiners-exams-page';

  private months = [
    '01', '02', '03', '04', '05', '06',
    '07', '08', '09', '10', '11', '12',
  ];

  private readableMonths = {
    '01': 'Январь',
    '02': 'Февраль',
    '03': 'Март',
    '04': 'Апрель',
    '05': 'Май',
    '06': 'Июнь',
    '07': 'Июль',
    '08': 'Август',
    '09': 'Сентябрь',
    10: 'Октябрь',
    11: 'Ноябрь',
    12: 'Декабрь',
  }

  private selectedYear = DateTime.local().year;

  @ExaminersModule.namespace.Action('getExaminersExamCountStatistic')
  private getExaminersExamCountStatistic: ExaminersModule['getExaminersExamCountStatistic'];

  @ExaminersModule.namespace.State('examsCountStatistic')
  private examsCountStatistic: ExaminersModule['examsCountStatistic'];

  created(): void {
    this.getExaminersExamCountStatistic(this.selectedYear);
  }

  getStatistic(statistic: MonthExamStatistic): string {
    if (statistic == null) return '-';
    return `<span class="${this.blockName}__hour-statistic">${statistic.total}</span> -
    <span class="${this.blockName}__hour-statistic">${statistic.rejected}</span> -
    <span class="${this.blockName}__hour-statistic">${statistic.awaiting}</span> =
    <span class="${this.blockName}__hour-statistic">${statistic.accepted}</span>`;
  }

  get yearFilterOptions(): YearSelectOption[] {
    const { year } = DateTime.local();
    return [
      {
        id: year,
        label: year.toString(),
        header: false,
      },
      {
        id: year - 1,
        label: (year - 1).toString(),
        header: false,
      },
    ];
  }

  onChangeYear(value: YearSelectOption): void {
    this.selectedYear = value.id;
    this.getExaminersExamCountStatistic(this.selectedYear);
  }
}
