


































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import { UserStatistic, Card } from '@/store/models/UserStatistic';
import { UserModule, AuthModule, ConfigModule } from '@/store/modules';
import date from '@/utils/filters/date';
import clearTags from '@/utils/filters/clear-tags';
import ExpansionPanel from '@/components/ExpansionPanel.vue';
import HelpPopover from '@/components/core/HelpPopover.vue';

  @Component({
    components: { HelpPopover, ExpansionPanel },
    filters: { date, clearTags },
  })
export default class Template extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'statistic-page';

  @Prop({ default: false })
  private isCurrentUser!: boolean;

  @AuthModule.namespace.State('currentUser')
  private currentUser: AuthModule['currentUser'];

  @AuthModule.namespace.State('isStudent')
  private isStudent: AuthModule['isStudent'];

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  @Prop({ default: null })
  private id!: string;

  private userStatistic: UserStatistic = null;

  @UserModule.namespace.Action('getStatistic')
  private getStatistic: UserModule['getStatistic'];

  @UserModule.namespace.Action('getOne')
  private getUser: UserModule['getOne'];

  @UserModule.namespace.State('developer')
  private developer: UserModule['developer'];

  private async beforeMount(): Promise<void> {
    this.userStatistic = await this.getStatistic(this.id);
    if (this.isStudent || this.userStatistic == null) {
      await this.$router.push({ name: 'Index' });
    }
  }

  private get stagedCards(): Card[] {
    return this.userStatistic.billingMonthProgress.filter((card) => card.stageLabel !== '');
  }

  private get simpleCards(): Card[] {
    return this.userStatistic.billingMonthProgress;
  }
}
