import {
  RiseUpLevel,
  UserDepartment,
  UserRole,
  SpecializationEnum,
} from '../enums';
import { RolesEnum } from '../../../private_modules/dunice-space-shared/types';

interface UserDepartmentModel {
  id: string;
  slug: UserDepartment;
  name: string;
}

interface PeriodProgress {
  date: string;
  level: string;
  name: string;
  specializationName: string;
  stageLabel: string;
}

export type UserSpecialization = {
  id: string;
  name: string;
  slug: string;
  version: string;
  gradePrefix: string | null;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: string;
  level: RiseUpLevel; // Level list
  role: UserRole; // is main role
  roles: { id: string; name: string; slug: RolesEnum; }[]; // is main role
  departments: UserDepartmentModel[]; // Department list
  periodProgress?: PeriodProgress[];
  needCongratulate: boolean;
  userSpecialization?: UserSpecialization;
  progressScore: {
    full: number;
    period: number;
  }
}

export type UsersByDepartments = Record<UserDepartment, User[]>;

export const buildUsersByDepartmentsEmptyObject = (): UsersByDepartments => ({} as UsersByDepartments);

export type UsersWithStatistic = User[];

export interface UserWithoutProfile {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  departmentId: string | null;
  specializationType: SpecializationEnum;
  role: UserRole;
}

export interface UsersSpecializations {
  id: string;
  specializationType: SpecializationEnum;
}

export type Permission = string;

export type CheckAvailable = {
  permissions: Permission;
}
