




















































import { Component, Vue } from 'vue-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';
import { DateTime } from 'luxon';

import store from '@/store';
import { AuthModule, ExamModule } from '@/store/modules';
import DepartmentSelect from '@/components/DepartmentSelect.vue';
import WeekSelect from '@/components/WeekSelect.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

@Component({
  components: { WeekSelect, DepartmentSelect },
})
export default class ExamList extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'exams-page';

  @AuthModule.namespace.Getter('isAdmin')
  private isAdmin: AuthModule['isAdmin'];

  @AuthModule.namespace.Getter('isStudent')
  private isStudent: AuthModule['isStudent'];

  @AuthModule.namespace.Getter('isAdminStaff')
  private isAdminStaff: AuthModule['isAdminStaff'];

  @ExamModule.namespace.Mutation('updateCurrentExamsWeekNumberFilter')
  private updateCurrentExamsWeekNumberFilter: ExamModule['updateCurrentExamsWeekNumberFilter'];

  private created() {
    let week = this.$route.query?.weekNumber;
    if (week == null) week = DateTime.local().toFormat('yyyy.WW');
    else if (week === 'All') week = null;
    this.updateCurrentExamsWeekNumberFilter(typeof week === 'string' ? week : null);
  }

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    if (to.name !== 'Exams') return next();
    if (store.getters['auth/isStudent']) return next('/exams/passing-exams');
    return next('/exams/by-weeks');
  }

  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
    if (to.name !== 'Exams') return next();
    if (store.getters['auth/isStudent']) return next('/exams/passing-exams');
    return next('/exams/by-weeks');
  }
}
