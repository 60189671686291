


































import { Component, Prop, Vue } from 'vue-property-decorator';

import { Exam } from '@/store/models/Exam';
import { ConfigModule, ExamModule } from '@/store/modules';
import ExaminingExamListItem from '@/components/ExamingExamList/ExaminingExamListItem.vue';
import ExaminingExamListItemMobile from '@/components/ExamingExamList/ExaminingExamListItemMobile.vue';
import SetExamDateTimeModal from '@/components/modals/SetExamDateTimeModal.vue';

@Component({
  components: { SetExamDateTimeModal, ExaminingExamListItem, ExaminingExamListItemMobile },
})
export default class ExaminingExamList extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'examining-exam-list';

  @Prop()
  private exams!: Exam[];

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  @ExamModule.namespace.Action('getAll')
  private getAll: ExamModule['getAll'];

  private openSetDateTimeModal(exam: Exam) {
    this.$modal.show('set-exam-date-time-modal', { exam });
  }
}
