











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AppChip extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'chip';

  @Prop({ default: 'block' })
  private type!: 'block' | 'competence';

  @Prop({ default: false })
  private isHtml: boolean;

  @Prop()
  private text!: string;
}
