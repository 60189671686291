
















import { Component, Vue } from 'vue-property-decorator';

import Select from '@/components/Select/Select.vue';
import { StatisticsModule } from '@/store/modules';
import CompetenciesStatisticList from '@/components/CompetenciesStatisticList.vue';
import date from '@/utils/filters/date';

import TIME_RANGE_LABELS from './constants/timeRangeLabels';
import { getTimeRangeValue } from './utils/getTimeRange';

interface TimeFilterOption {
  id: string;
  label: string;
}

@Component({
  components: {
    CompetenciesStatisticList,
    Select,
  },
  filters: { date },
})
export default class CompetenciesStatistics extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'competencies-statistics';

  private selectedTime = TIME_RANGE_LABELS.forLastMonth;

  @StatisticsModule.namespace.Action('getCompetenciesStatistic')
  private getCompetenciesStatistic: StatisticsModule['getCompetenciesStatistic'];

  @StatisticsModule.namespace.State('competenciesStatistics')
  private competenciesStatistics: StatisticsModule['competenciesStatistics'];

  private created(): void {
    const range = getTimeRangeValue(this.selectedTime);

    this.getCompetenciesStatistic(range);
  }

  get timeFilterOptions(): TimeFilterOption[] {
    return [
      {
        label: TIME_RANGE_LABELS.forLastMonth,
        id: TIME_RANGE_LABELS.forLastMonth,
      },
      {
        label: TIME_RANGE_LABELS.forLastThreeMonth,
        id: TIME_RANGE_LABELS.forLastThreeMonth,
      },
      {
        label: TIME_RANGE_LABELS.forLastYear,
        id: TIME_RANGE_LABELS.forLastYear,
      },
      {
        label: TIME_RANGE_LABELS.forAllTime,
        id: TIME_RANGE_LABELS.forAllTime,
      },
    ];
  }

  onChangeTime(value: TimeFilterOption): void {
    this.selectedTime = value.id;

    const range = getTimeRangeValue(this.selectedTime);

    this.getCompetenciesStatistic(range);
  }
}
