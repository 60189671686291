








import { Component, Vue } from 'vue-property-decorator';
import { StatisticsModule } from '@/store/modules';
import ExaminersStatisticList from '@/components/ExaminersStatisticList.vue';
import date from '@/utils/filters/date';

@Component({
  components: {
    ExaminersStatisticList,
  },
  filters: { date },
})
export default class ExaminersStatistic extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'examiners-statistics';

  @StatisticsModule.namespace.Action('getExaminersStatistic')
  private getCompetenciesStatistic: StatisticsModule['getExaminersStatistic'];

  @StatisticsModule.namespace.State('examinersStatistic')
  private examinersStatistic: StatisticsModule['examinersStatistic'];

  private created(): void {
    this.getCompetenciesStatistic();
  }
}
