import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { BindingHelpers } from 'vuex-class/lib/bindings';

import { managementStaff, students } from '@/store/enums';
import { User, Permission, CheckAvailable } from '@/store/models/User';
import adapter from '@/store/adapter';
import Logger from '@/utils/Logger';

import { RiseUpProfile, RolesEnum } from '@/../private_modules/dunice-space-shared/types';

export const MODULE_NAME = 'auth';
export const getAuthNamespace = (): BindingHelpers => namespace(MODULE_NAME);

@Module({ stateFactory: true, namespaced: true })
export default class AuthModule extends VuexModule {
  private static _namespace: BindingHelpers = null;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getAuthNamespace();

    return this._namespace;
  }

  public accessToken: string = localStorage.getItem('accessToken');

  public currentUser: RiseUpProfile = null;

  public permissions: Permission[] = [];

  public get isAuthenticated(): boolean {
    return this.currentUser != null;
  }

  public get role(): RolesEnum { return this.currentUser?.roles?.[0]?.slug ?? RolesEnum.notApproved; }

  public get isAdmin(): boolean {
    return this.currentUser.roles.some((role) => role.slug === 'admin');
  }

  public get isHeadOfDepartment(): boolean {
    return this.currentUser.roles.some((role) => role.slug === 'headOfGroup' || role.slug === 'headOfUnit');
  }

  public get isManager(): boolean {
    return this.currentUser.roles.some((role) => role.slug === 'manager'
    || role.slug === RolesEnum.headOfProjectOfficeDepartment);
  }

  public get isDeveloper(): boolean {
    return this.currentUser.roles.some((role) => role.slug === RolesEnum.developer || role.slug === RolesEnum.curator);
  }

  public get isNotApproved(): boolean {
    return this.currentUser.roles.some((role) => role.slug === 'notApproved');
  }

  public get isManagementStaff(): boolean {
    return this.currentUser?.roles?.some(({ slug }) => managementStaff.includes(slug));
  }

  private get isAdminStaff(): boolean {
    return this.currentUser.roles.some(({ slug }) => slug === RolesEnum.admin || slug === RolesEnum.unitlead);
  }

  private get isStudent(): boolean {
    return this.currentUser.roles.some(({ slug }) => students.includes(slug));
  }

  public get canApproveCompetence(): boolean {
    if (this.permissions.length === 0) return false;
    return this.permissions.some((item) => item === 'USER_COMPETENCIES:ALL:CREATE');
  }

  // TODO add new roles, check logic
  public get hasAccess(): (role: string, strict?: boolean) => boolean {
    return (role: string, strict: boolean = false) => {
      if (strict) return role === this.role;

      if (role === RolesEnum.admin && this.role === RolesEnum.admin) return true;

      const hasUnitLeadAccess = role === RolesEnum.unitlead
        && this.isAdminStaff;

      if (hasUnitLeadAccess) return true;

      const hasManagerAccess = role === RolesEnum.manager
        && this.currentUser?.roles?.some(({ slug }) => managementStaff.includes(slug));

      if (hasManagerAccess) return true;

      const hasDeveloperAccess = role === RolesEnum.developer
        && this.currentUser?.roles?.some(({ slug }) => managementStaff.concat(...students).includes(slug));

      if (hasDeveloperAccess) return true;

      return role === RolesEnum.notApproved;
    };
  }

  @Mutation
  public setAccessToken(token: string): void {
    this.accessToken = token;
  }

  @Mutation
  public setCurrentUser(user: RiseUpProfile): void {
    this.currentUser = user;
  }

  @Mutation
  public setPermissions(permissions: Permission[]): void {
    this.permissions = permissions;
  }

  @Action
  public async signIn(): Promise<void> {
    try {
      window.location.replace(`${this.context.rootState.config.ssoHostUri}?redirectURI=${window.location.href}`);
    } catch (error) {
      Logger.error('Store::Auth:signIn', error);
    }

    return null;
  }

  @Action
  public async signInSuccess(token: string): Promise<void> {
    try {
      localStorage.setItem('accessToken', token);
      this.context.commit('setAccessToken', token);
    } catch (error) {
      Logger.error('Store::Auth:signInSuccess', error);
    }

    return this.context.dispatch('whoAmI');
  }

  @Action
  private async canUseNewRiseUp(): Promise<boolean> {
    try {
      await adapter.get('/permissions/check-specialization');
      return true;
    } catch (error) {
      return false;
    }
  }

  @Action
  public async whoAmI(): Promise<User> {
    try {
      const response = await adapter.get<User>('users/me');
      const user = response.data;
      this.context.commit('setCurrentUser', user);
      const canUseNewRiseUp = await this.context.dispatch('canUseNewRiseUp');
      if (this.context.getters.isDeveloper && canUseNewRiseUp) {
        window.location.replace(process.env.VUE_APP_NEW_RISE_UP_URL);
      }
      await this.context.dispatch('checkAvailable');
      return user;
    } catch (error) {
      Logger.error('Store::Auth:whoAmI', error);
      return null;
    }
  }

  @Action
  public async signOut(): Promise<User> {
    await adapter.get('auth/logout');
    this.context.commit('setCurrentUser', null);
    return null;
  }

  @Action
  public async checkAvailable(): Promise<void> {
    try {
      const { data } = await adapter.get<CheckAvailable>('/permissions/check-available');
      this.context.commit('setPermissions', data.permissions);
    } catch (error) {
      Logger.error('Store::Auth:checkAvailable', error);
    }
  }
}
