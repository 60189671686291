





















import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { },
})
export default class WaitingPageThankYouText extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'waiting-page-thank-you-text';
}
