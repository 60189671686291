


















import { Component, Vue } from 'vue-property-decorator';

import {
  ConfigModule,
} from '@/store/modules';
import { Competence } from '@/store/models/Competence';
import CompetenceName from '@/components/CompetenceName.vue';

@Component({
  components: { CompetenceName },
})
export default class ExamCreatedModal extends Vue {
  private blockName = 'exam-created-modal';

  private competence: Competence = null;

  @ConfigModule.namespace.State('isMobile')
  private isMobile: boolean;

  private receiveData(event: { params: { competence: Competence }}) {
    this.competence = event.params.competence;
  }

  private async onClose(): Promise<void> {
    this.$modal.hide(this.blockName);
  }
}
