

































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: { },
})
export default class CongratulationsBackgroundSvg extends Vue {
  @Prop({ default: '' })
  private className!: string;
}
