


































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import marked from 'marked';
import CloseIcon from 'vue-material-design-icons/Close.vue';

import { Competence } from '@/store/models/Competence';
import Select from '@/components/Select/Select.vue';
import {
  AuthModule,
  CompetenceModule,
  ConfigModule,
  ExamModule,
  SpecializationModule,
} from '@/store/modules';
import CompetenceDuration from '@/components/CompetenceDuration.vue';
import CompetenceName from '@/components/CompetenceName.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';

import { RiseUpProfile } from '../../private_modules/dunice-space-shared/types';

// Override function
const renderer = new marked.Renderer();

renderer.link = function link(href: string, title: string, text: string) {
  return `<a target="_blank" href="${href}" title="${title == null ? text : title}">${text}</a>`;
};

@Component({
  components: {
    CompetenceName,
    CompetenceDuration,
    CloseIcon,
    Select,
    ConfirmModal,
  },
})
export default class CompetenceInfo extends Vue {
  @Prop({ default: () => ({}) }) private mods!: Modificator;

  @Prop({ default: (): Competence => null }) private competence!: Competence;

  @Prop({ default: (): RiseUpProfile => null }) private user!: RiseUpProfile;

  @Prop({ default: () => true }) private isCurrentUser!: boolean;

  @Prop({ default: () => false }) private isAllowed!: boolean;

  private blockName: string = 'competence-info';

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  @ExamModule.namespace.State('error')
  private examError: ExamModule['error'];

  @AuthModule.namespace.Getter('isStudent')
  private isStudent: AuthModule['isStudent'];

  @AuthModule.namespace.Getter('canApproveCompetence')
  private canApproveCompetence: AuthModule['canApproveCompetence'];

  @ExamModule.namespace.Action('setStatus')
  private setExamStatus: ExamModule['setStatus'];

  @SpecializationModule.namespace.Action('getRiseProgress')
  private refetchRiseUpProgress: SpecializationModule['getRiseProgress'];

  @CompetenceModule.namespace.Action('approveCompetence')
  private approveCompetenceAction: CompetenceModule['approveCompetence'];

  private get showTryButton(): boolean {
    return !this.competence.passed
      && this.isAllowed
      && this.isStudent
      && !this.competence.hasRegisteredExam
      && this.competence.duration > 0;
  }

  private get cancelExamError(): string {
    return Array.isArray(this.examError.setStatus?.message)
      ? this.examError.setStatus.message.join()
      : this.examError.setStatus?.message;
  }

  private compileMarkdown(template: string): string {
    if (template == null) return '';

    return marked(template, { renderer });
  }

  private tryExam() {
    this.$modal.hide('competence-info');
    this.$modal.show('try-exam', { competence: this.competence });
  }

  private async cancelExam() {
    if (this.competence.isCancellable) {
      const result = await this.setExamStatus({ id: this.competence.examId, status: 'canceled' });
      await this.refetchRiseUpProgress({});
      if (result != null) this.$modal.hide('competence-info');
    }
  }

  private showConfirmModal() {
    this.$modal.show('confirm-modal');
  }

  private closeConfirmModal() {
    this.$modal.hide('confirm-modal');
  }

  private async approveCompetence() {
    await this.approveCompetenceAction({
      userId: this.user.id,
      competenceId: this.competence.id,
    });
    await this.refetchRiseUpProgress({ user: this.user, level: this.competence.level });
    this.closeConfirmModal();
    this.$modal.hide('competence-info');
  }
}
