












import { Component, Model, Vue } from 'vue-property-decorator';

import Select from '@/components/Select/Select.vue';
import { UserModule } from '@/store/modules';
import { AppSelectOption } from '@/components/core/AppSelect.vue';

@Component({
  components: { Select },
})
export default class ExaminerSelect extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'examiner-select';

  private get options(): AppSelectOption[] {
    return this.techLeads?.map((lead) => ({
      value: lead.id,
      label: `${lead.firstName} ${lead.lastName}`,
    })) ?? [];
  }

  @UserModule.namespace.State('techLeads')
  private techLeads: UserModule['techLeads'];

  @UserModule.namespace.Action('getTechLeads')
  private getTechLeads: UserModule['getTechLeads'];

  @Model('change')
  private examiner!: AppSelectOption;

  created(): void {
    this.getTechLeads();
  }
}
