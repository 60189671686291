import {
  Module, VuexModule, Action, Mutation,
} from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { BindingHelpers } from 'vuex-class/lib/bindings';

import adapter from '@/store/adapter';
import Logger from '@/utils/Logger';
import { Room } from '@/store/models/Room';

export const MODULE_NAME = 'room';
export const getRoomNamespace = (): BindingHelpers => namespace(MODULE_NAME);

@Module({ stateFactory: true, namespaced: true })
export default class RoomModule extends VuexModule {
  private static _namespace: BindingHelpers = null;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getRoomNamespace();

    return this._namespace;
  }

  public rooms: Room[] = [];

  public roomHash: Record<string, Room> = {};

  @Mutation
  public setRooms(rooms: Room[]): void {
    this.rooms = rooms;
    for (const room of rooms) this.roomHash[room.id] = room;
  }

  @Mutation
  public setRoom(room: Room): void {
    const index = this.rooms.findIndex(({ id }) => id === room.id);
    this.rooms.splice(index, 1, room);
    this.roomHash[room.id] = room;
  }

  @Action
  public async getOne(roomId: string): Promise<Room> {
    try {
      const response = await adapter.get<Room>(`/rooms/${roomId}`, {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      const room = response.data;
      this.context.commit('setRoom', room);
      return room;
    } catch (error) {
      Logger.error('Store::RoomModule:getOne', error);
      return null;
    }
  }

  @Action
  public async getAll(): Promise<Room[]> {
    try {
      const response = await adapter.get<Room[]>('/rooms', {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      const rooms = response.data;
      this.context.commit('setRooms', rooms);
      return rooms;
    } catch (error) {
      Logger.error('Store::RoomModule:getAll', error);
      return null;
    }
  }
}
