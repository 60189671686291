// eslint-disable-next-line import/prefer-default-export
export enum DirectionEnum {
  angular = 'Angular',
  react = 'React',
  vue = 'Vue',
  reactNative = 'React Native',
  python = 'Python',
  golang = 'Golang',
  php = 'PHP',
  node = 'Node',
  ruby = 'Ruby',
  nestJs = 'NestJS',
  django = 'Django',
  sinatra = 'Sinatra',
  laravel = 'Laravel',
  ror = 'Ruby on Rails',
  gin = 'Gin'
}
