



















































import { Component, Prop, Vue } from 'vue-property-decorator';

import { CompetenciesStatistic } from '@/store/models/Statistic';
import UserWithLevel from '@/components/UserWithLevel.vue';
import date from '@/utils/filters/date';

@Component({
  components: { UserWithLevel },
  filters: { date },
})
export default class CompetenciesStatisticList extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'competencies-statistic-list';

  @Prop() private data: CompetenciesStatistic[];
}
