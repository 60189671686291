import axios from 'axios';

import config from '@/configs/config';

const instance = axios.create({
  baseURL: config.host,
  timeout: 10000,
  withCredentials: true,
});

export interface BackEndError {
  error: string;
  message: string[] | string;
  statusCode: number;
}

export default instance;
