import { DateTime } from 'luxon';

interface Params {
  leftSideAmount?: number;
  rightSideAmount?: number;
}

interface Output {
  minWeekNumber: string;
  maxWeekNumber: string;
}

export default (date: Date, params: Params = {}): Output => {
  const {
    leftSideAmount = 10,
    rightSideAmount = 2,
  } = params;
  const luxon = DateTime.fromJSDate(date);
  let leftYear = luxon.weekYear;
  let rightYear = luxon.weekYear;
  let leftWeekNumber = luxon.weekNumber - leftSideAmount;
  let rightWeekNumber = luxon.weekNumber + rightSideAmount;
  const lastWeekNumber = luxon.endOf('year').weekNumber;
  if (leftWeekNumber <= 0) {
    leftYear -= 1;
    leftWeekNumber = luxon.set({ year: leftYear }).endOf('year').weekNumber - Math.abs(leftWeekNumber);
  }
  if (rightWeekNumber > lastWeekNumber) {
    rightYear += 1;
    rightWeekNumber -= lastWeekNumber;
  }
  return {
    minWeekNumber: `${leftYear}.${String(leftWeekNumber).padStart(2, '0')}`,
    maxWeekNumber: `${rightYear}.${String(rightWeekNumber).padStart(2, '0')}`,
  };
};
