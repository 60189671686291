




import { Vue, Component } from 'vue-property-decorator';
import VueSelect from '@/components/VueSelect/VueSelect.vue';

// TODO
@Component({
  components: { VueSelect },
})
export default class AnalysisSortSelect extends Vue {
  private value = 1;

  private options = [
    {
      id: 1,
      label: 'Option 1',
      header: false,
      value: 'value-1',
    },
    {
      id: 2,
      label: 'Option 2',
      header: false,
      value: 'value-1',
    },
  ];
}
