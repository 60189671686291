export enum ExamStatusEnum {
  'initiated' = 'Создано', // когда сдающий сказал что хочет сдавать
  'canceled' = 'Отменено', // когда сдающий отменил сдачу до того как будет назначен принимающий
  'assigned' = 'Назначен принимающий', // когда назначен принимающий
  'ready' = 'Назначено время', // когда назначено время
  'passed' = 'Сдал(а)', // экзамен сдан
  'sick' = 'Заболел(а)', // сдающий заболел
  'rejected' = 'Отказался(ась)', // сдающий отказался сдавать
  'failed' = 'Не сдал(а)', // экзамен не сдан
  'timeOver' = 'Не хватило времени', // не успел
}

export type ExamStatus = keyof typeof ExamStatusEnum;

export enum ExamStatusTransitions {
  'canceled' = 'cancel',
  'assigned' = 'assign',
  'ready' = 'ready',
  'passed' = 'pass',
  'sick' = 'sick',
  'rejected' = 'reject',
  'failed' = 'fail',
  'timeOver' = 'timeOver',
}

export const examsFinalResults: ExamStatus[] = ['passed', 'rejected', 'sick', 'failed', 'timeOver'];

export const examsResults: ExamStatus[] = [
  'initiated',
  'canceled',
  'assigned',
  'ready',
  'passed',
  'rejected',
  'sick',
  'failed',
  'timeOver',
];
