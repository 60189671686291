



















import { Vue, Component, Prop } from 'vue-property-decorator';
import 'vue-select/dist/vue-select.css';
import VSelect from 'vue-select';
import { SelectOption } from '@/components/Select/SelectOption';

@Component({
  components: { VSelect },
})
export default class VueSelect extends Vue {
  private blockName: string = 'vue-select';

  @Prop()
  options: SelectOption[];

  @Prop()
  private value: number | null | string;

  @Prop()
  onSelect: (id: number | string) => void;

  private reduce(option: SelectOption): string | number {
    return option.id;
  }
}
