/* eslint-disable no-bitwise */

// Return one of option, at an index built by bitwise flag principle (0xab)
export const bitwiseConditionCarried = <T = string>(
  options: [T, T, T, T], // [!a && !b, a && !b, a && !b, a && b]
) => (
    a: boolean, // as 0x?0
    b: boolean, // as 0x0?
  ): T => options[(Number(a) << 1) | Number(b)];

// Return one of option, at an index built by bitwise flag principle (0xab)
export default <T = string>(
  options: [T, T, T, T], // [!a && !b, a && !b, a && !b, a && b]
  a: boolean, // as 0x?0
  b: boolean, // as 0x0?
): T => options[(Number(a) << 1) | Number(b)];
