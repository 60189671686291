











































































import {
  Vue, Component,
} from 'vue-property-decorator';

import { ConfigModule, ExamModule } from '@/store/modules';
import CompetenceName from '@/components/CompetenceName.vue';
import { Exam } from '@/store/models/Exam';
import UserWithLevel from '@/components/UserWithLevel.vue';
import ExamStatusSelect from '@/components/ExamStatusSelect.vue';
import { examsFinalResults, ExamStatus } from '@/store/enums';

@Component({
  components: {
    ExamStatusSelect,
    UserWithLevel,
    CompetenceName,
  },
})
export default class SetExamStatusModal extends Vue {
  private blockName: string = 'set-exam-status-modal';

  private get mods(): Modificator {
    return {
      mobile: this.isMobile ? '@' : '',
    };
  }

  private exam: Exam = null;

  private examResult: ExamStatus = null;

  private examinerComment: string = null;

  private linkToExam: string = null;

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  @ExamModule.namespace.Action('setStatus')
  private setExamStatus: ExamModule['setStatus'];

  @ExamModule.namespace.State('error')
  private examError: ExamModule['error'];

  private get setStatusError() {
    return Array.isArray(this.examError.setStatus?.message)
      ? this.examError.setStatus.message.join()
      : this.examError.setStatus?.message;
  }

  private get isSaveDisabled() {
    return !examsFinalResults.includes(this.examResult);
  }

  private receiveData(event: { params: { exam: Exam }}) {
    this.exam = event.params.exam;
    this.examResult = this.exam.status;
    this.examinerComment = this.exam.examinerComment;
    this.linkToExam = this.exam.linkToLine;
  }

  private async onSave() {
    await this.setExamStatus({
      id: this.exam.id,
      status: this.examResult,
      examinerComment: this.examinerComment,
      linkToLine: this.linkToExam,
    });
    if (this.examError.setStatus == null) this.onCancel();
  }

  private onCancel() {
    this.$modal.hide(this.blockName);
  }

  private reset() {
    this.exam = null;
    this.examResult = null;
  }

  private beforeClose(): void {
    this.reset();
    this.$emit('before-close');
  }
}
