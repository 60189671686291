











































import { Component, Prop, Vue } from 'vue-property-decorator';
import { UsersAnalyticByDepartments } from '@/store/models/Statistic';
import UserWithLevel from '@/components/UserWithLevel.vue';
import date from '@/utils/filters/date';
import ExpansionPanel from '@/components/ExpansionPanel.vue';

@Component({
  components: { UserWithLevel, ExpansionPanel },
  filters: { date },
})
export default class AnalyticsListMobile extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'analytics-list-mobile';

  @Prop() private data: UsersAnalyticByDepartments[];
}
