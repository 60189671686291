



























import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

import { ConfigModule } from '@/store/modules';

@Component({
  components: { },
})
export default class ExpansionPanel extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'panel';

  private show: boolean = false;

  @Prop(Number)
  private value: number | undefined;

  @Prop({ default: '' })
  private name: string;

  @Prop({ default: false })
  private isHtml: boolean;

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  private toggleShow() {
    this.show = !this.show;
  }
}
