





























import {
  Component, Model, Vue, Watch,
} from 'vue-property-decorator';

import { ExamStatus, ExamStatusEnum, examsFinalResults } from '@/store/enums';
import Select from '@/components/Select/Select.vue';
import { AppSelectOption } from '@/components/core/AppSelect.vue';

const statusesOptions: AppSelectOption<ExamStatus>[] = examsFinalResults.map((status) => ({
  label: ExamStatusEnum[status],
  // eslint-disable-next-line global-require,import/no-dynamic-require
  icon: require(`@/assets/${status}.svg`),
  value: status,
}));

@Component({
  components: { Select },
})
export default class ExamStatusSelect extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'exam-status-select';

  private options: AppSelectOption<ExamStatus>[] = statusesOptions;

  @Model('change')
  private status!: ExamStatus;

  private selectedStatus: AppSelectOption<ExamStatus> = null;

  created(): void {
    if (this.status) {
      this.selectedStatus = this.options.find((option) => option.value === this.status) ?? null;
    }
  }

  @Watch('selectedStatus')
  private updateStatus() {
    this.$emit('change', this.selectedStatus?.value ?? null);
  }
}
