























































import {
  Vue, Component, Prop, Ref, Watch,
} from 'vue-property-decorator';

import { ConfigModule } from '@/store/modules';

import { SelectOption } from './SelectOption';

const OPTIONS_LIST_TOP_MARGIN = 7;
const OPTIONS_LIST_X_MARGIN = 7;

@Component({
  components: { },
})
export default class Select extends Vue {
  @Prop()
  private value: number | undefined | string;

  @Prop({ default: '' })
  private name: string;

  @Prop({ default: [] })
  private options: SelectOption[];

  @Prop({ default: true })
  private closeOnSelect: boolean;

  @Prop({ default: 'center' })
  private positionList: string;

  @Prop({ default: '' })
  private placeholder: string;

  @Prop({ default: false })
  private disabled: boolean;

  @Prop({ default: false })
  private isError: boolean;

  @Ref('imageRef') private readonly imageRef!: HTMLDivElement;

  @Ref('optionsRef') private readonly optionsRef!: HTMLDivElement;

  @Ref('selectRef') private readonly selectRef!: HTMLDivElement;

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  private blockName: string = 'select';

  private mods: Modificator = {
    header: '@',
  };

  private blockMods: Modificator = {
    mobile: '@',
  };

  private show: boolean = false;

  private boundedHideOptions: (event: MouseEvent) => void = null;

  private mounted() {
    this.boundedHideOptions = this.hideOptions.bind(this);
    document.addEventListener('click', this.boundedHideOptions);
  }

  private beforeDestroy() {
    document.removeEventListener('click', this.boundedHideOptions);
  }

  private get selectedValue() {
    const option = this.options.find((item) => item.id === this.value);
    return option?.label ?? this.placeholder;
  }

  private get headerOptions() {
    return this.options.filter((item) => item.header);
  }

  private get mainOptions() {
    return this.options.filter((item) => !item.header);
  }

  private get optionsMods(): Modificator {
    const hasHeaderOptions = this.headerOptions.length > 0;
    const mods = {
      'without-header': !hasHeaderOptions ? '@' : '',
      [this.positionList]: '@',
    };
    return mods;
  }

  private setListPosition(): void {
    const {
      top,
      left,
      height,
      width,
      right,
    } = this.imageRef.getBoundingClientRect();
    const { innerWidth } = window;
    const { width: optionsListWidth } = this.optionsRef.getBoundingClientRect();
    if (this.positionList === 'center') {
      let leftPos = left + (width / 2) - optionsListWidth / 2;
      if (left + (width / 2) + optionsListWidth / 2 >= innerWidth) {
        leftPos = innerWidth - optionsListWidth - OPTIONS_LIST_X_MARGIN;
      }
      this.optionsRef.style.left = `${leftPos}px`;
      this.optionsRef.style.top = `${top + height + OPTIONS_LIST_TOP_MARGIN}px`;
    } else if (this.positionList === 'right') {
      let leftPos = left + (width / 2) - optionsListWidth / 2;
      if (right - optionsListWidth < 0) {
        leftPos = OPTIONS_LIST_X_MARGIN;
      }
      this.optionsRef.style.top = `${top + height + OPTIONS_LIST_TOP_MARGIN}px`;
      this.optionsRef.style.left = `${leftPos}px`;
    } else {
      let leftPos = left;
      if (leftPos + optionsListWidth >= innerWidth) {
        leftPos = innerWidth - optionsListWidth - OPTIONS_LIST_X_MARGIN;
      }
      this.optionsRef.style.top = `${top + height + OPTIONS_LIST_TOP_MARGIN}px`;
      this.optionsRef.style.left = `${leftPos}px`;
    }
  }

  @Watch('show')
  onShowChanged(val: boolean): void {
    if (val) this.$nextTick(this.setListPosition);
  }

  private optionClass(id: number | string) {
    if (id !== this.value) return `${this.blockName}__option`;
    return `${this.blockName}__option ${this.blockName}__option--active`;
  }

  // if one of the selections is open, close it when you open another
  private hideOptions(event: MouseEvent) {
    if (this.selectRef == null) return;
    if (this.selectRef !== event.target && event.target instanceof Node && !this.selectRef.contains(event.target)) {
      this.show = false;
    }
  }

  private toggleShow(event: MouseEvent) {
    if (this.disabled) return;
    if (this.show && this.optionsRef) {
      // if the target element is an option block
      // or its child and the closeOnSelect is set to false, prevent closing
      if (event.target === this.optionsRef) return;
      if (event.target instanceof Node && this.optionsRef.contains(event.target) && !this.closeOnSelect) return;
    }
    this.show = !this.show;
  }

  private onClickOption(event: MouseEvent, id: number | string) {
    this.show = false;
    this.$emit('onSelect', { id, name: this.name }, event);
  }
}
