import { UserDepartmentEnum } from '@/store/enums/department.enum';

const departmentDict = <const>{
  all: 'Все отделы',
  ...UserDepartmentEnum,
};

type DepartmentDictKeyType = keyof typeof departmentDict;

export default (key: DepartmentDictKeyType): typeof departmentDict[DepartmentDictKeyType] => departmentDict[key];
