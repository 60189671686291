





































































































import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import { DateTime } from 'luxon';

import {
  AuthModule, ConfigModule, ExamModule, FrameModule,
} from '@/store/modules';
import { Exam } from '@/store/models/Exam';
import UserWithLevel from '@/components/UserWithLevel.vue';
import UserUnavailability from '@/components/UserUnavailability.vue';
import { AppSelectOption } from '@/components/core/AppSelect.vue';
import ExaminerSelect from '@/components/ExaminerSelect.vue';
import CompetenceName from '@/components/CompetenceName.vue';
import { ExamDatetimeFrame } from '@/store/models/ExamDatetimeFrame';

@Component({
  components: {
    CompetenceName,
    ExaminerSelect,
    UserUnavailability,
    UserWithLevel,
  },
})
export default class SetExamDateTimeModal extends Vue {
  private blockName: string = 'set-exam-date-time-modal';

  private get mods(): Modificator {
    return {
      mobile: this.isMobile ? '@' : '',
    };
  }

  private exam: Exam = null;

  private examiner: AppSelectOption = null;

  private date: string = null;

  private frame: AppSelectOption<string[]> = null;

  private framesToReset: Set<string> = new Set();

  private get someRequiredFieldIsEmpty(): boolean {
    return [this.frame, this.date].some((field) => field == null);
  }

  private get canSetDatetime(): boolean {
    return ['assigned', 'ready'].includes(this.exam?.status);
  }

  private get canSetExaminer(): boolean {
    return this.exam?.status === 'initiated';
  }

  @FrameModule.namespace.State('list')
  private frames: FrameModule['list'];

  @FrameModule.namespace.State('error')
  private framesError: FrameModule['error'];

  @FrameModule.namespace.Action('getAll')
  private getFrames: FrameModule['getAll'];

  @FrameModule.namespace.Action('bindExam')
  private bindExam: FrameModule['bindExam'];

  @FrameModule.namespace.Action('clearStateErrors')
  private clearStateErrors: FrameModule['clearStateErrors'];

  @FrameModule.namespace.Action('reset')
  private resetFrame: FrameModule['reset'];

  @ExamModule.namespace.Action('assignExaminer')
  private assignExaminer: ExamModule['assignExaminer'];

  @ExamModule.namespace.Action('setStatus')
  private setExamStatus: ExamModule['setStatus'];

  @ExamModule.namespace.Action('getAll')
  private getAllExams: ExamModule['getAll'];

  @ExamModule.namespace.Action('cancelExam')
  private cancelExam: ExamModule['cancelExam'];

  @ExamModule.namespace.State('error')
  private examError: ExamModule['error'];

  @ExamModule.namespace.Mutation('setError')
  private setError: ExamModule['setError'];

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  @AuthModule.namespace.Getter('isAdminStaff')
  private isAdminStaff: AuthModule['isAdminStaff'];

  private get setStatusError() {
    return Array.isArray(this.examError.setStatus?.message)
      ? this.examError.setStatus.message.join()
      : this.examError.setStatus?.message;
  }

  private get bindExamError() {
    return Array.isArray(this.framesError.bindExam?.message)
      ? this.framesError.bindExam.message.join()
      : this.framesError.bindExam?.message;
  }

  private get roomFrameOptions() {
    const filteredFrames = this.date
      ? this.frames
        .filter((frame) => {
          const frameDate = DateTime.fromISO(frame.frame);
          const chosenDate = DateTime.fromFormat(this.date, 'yyyy-LL-dd');
          return frameDate.hasSame(chosenDate, 'day');
        })
      : this.frames;
    return filteredFrames.map((frame) => this.createFrameOption(frame));
  }

  private get isCancelable(): boolean {
    const examWeekMonday = DateTime.fromFormat(String(this.exam.weekNumber), 'kkkk.WW').set({
      hour: 12,
      minute: 0,
    });
    const diff = examWeekMonday.diffNow('minutes');
    return diff.minutes > 0 && this.exam.status === 'initiated';
  }

  private isDateDisabled(date: Date) {
    const calendarDate = DateTime.fromJSDate(date);
    const parsedFrameDates = this.frames.map((frame) => DateTime.fromISO(frame.frame));

    return !parsedFrameDates.some((dateTime) => dateTime.hasSame(calendarDate, 'day'));
  }

  @Watch('date')
  private onDateChanged(date: string, oldDate: string) {
    if (oldDate !== null) {
      this.frame = null;
    }
  }

  @Watch('frame')
  private onFrameChanged(frame: AppSelectOption<string[]>) {
    if (frame !== null) {
      this.addFrameForReset(frame);
      frame.value.forEach((frameId) => this.framesToReset.delete(frameId));
    }
  }

  private receiveData(event: { params: { exam: Exam }}) {
    this.exam = event.params.exam;
    this.getFrames({
      weekNumber: DateTime.local().weekNumber,
      competenceId: this.exam.competenceId,
      examId: this.exam.id,
    });

    if (this.exam.examiner != null) {
      const { examiner } = this.exam;
      this.examiner = {
        value: examiner.id,
        label: `${examiner.firstName} ${examiner.lastName}`,
      };
    }

    if (this.exam.frames[0] != null) {
      const [examFrame, secondFrame] = this.exam.frames;
      this.frame = this.createFrameOption(examFrame, secondFrame);
      this.date = DateTime.fromISO(examFrame.frame).toFormat('yyyy-LL-dd');
    }
  }

  private async assignExaminerToExam() {
    await this.assignExaminer({
      examId: this.exam.id,
      examinerId: this.examiner.value,
    });
    await this.getAllExams();
    this.onCancel();
  }

  private async onSave() {
    await this.bindExam({ examId: this.exam.id, frames: this.frame.value });
    this.framesToReset.forEach((examFrame) => this.resetFrame(examFrame));
    const isBindSuccess = this.framesError.bindExam === null;
    if (isBindSuccess) await this.setExamStatus({ id: this.exam.id, status: 'ready' });
    if (isBindSuccess && this.examError.setStatus === null) this.onCancel();
  }

  private onCancel() {
    this.$modal.hide('set-exam-date-time-modal');
    this.reset();
  }

  private reset() {
    this.frame = null;
    this.framesToReset = new Set();
    this.date = null;
    this.examiner = null;
    this.exam = null;
    this.clearStateErrors(['bindExam', 'update']);
    this.setError({ setStatus: null });
  }

  private addFrameForReset(frameOption: AppSelectOption<string[]>) {
    if (this.exam.frames) {
      const intersectedFrames = this.exam.frames
        .filter((examFrame) => frameOption.value.some((frameId) => examFrame.id !== frameId));

      intersectedFrames.forEach((frame) => this.framesToReset.add(frame.id));
    }
  }

  private setStatus() {
    this.$modal.show('set-exam-status-modal', { exam: this.exam });
    this.$modal.hide(this.blockName);
  }

  private createFrameOption(frame: ExamDatetimeFrame, nextFrame: Pick<ExamDatetimeFrame, 'id' | 'frame'> = null) {
    const value = [frame.id];
    const secondFrameValue = frame.secondFrame || nextFrame;
    let secondFrame = '';
    const mainFrame = DateTime.fromISO(frame.frame);
    if (secondFrameValue) {
      value.push(secondFrameValue.id);
      secondFrame = `-${mainFrame.plus({ hours: 1 }).toFormat('HH:mm')}`;
    }
    return {
      label: `${mainFrame.toFormat('HH:mm')}${secondFrame} - ${frame.roomName}`,
      value,
    };
  }

  private async onCancelExam() {
    const result = await this.cancelExam({ id: this.exam.id });
    if (result != null) {
      await this.getAllExams();
      this.$modal.hide(this.blockName);
    }
  }

  private beforeClose(): void {
    this.reset();
    this.$emit('before-close');
  }
}
