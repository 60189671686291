import { DateTime } from 'luxon';

import TIME_RANGE_LABELS from '../constants/timeRangeLabels';

export type TimeRangeValue = {
  from?: string | null;
};

const getRangeValueFrom = (params: Record<string, number>) => {
  const { weekNumber, year } = DateTime.local().minus(params);
  return `${year}.${weekNumber}`;
};

export const getTimeRangeValue = (label: string): TimeRangeValue => {
  if (label === TIME_RANGE_LABELS.forLastMonth) {
    const from = getRangeValueFrom({ month: 1 });

    return { from };
  }

  if (label === TIME_RANGE_LABELS.forLastThreeMonth) {
    const from = getRangeValueFrom({ month: 3 });

    return { from };
  }

  if (label === TIME_RANGE_LABELS.forLastYear) {
    const from = getRangeValueFrom({ year: 1 });

    return { from };
  }

  return { from: null };
};
