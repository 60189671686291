import { Level } from './users';
import { Durations } from './exams';

export enum SpecializationEnum {
  'webFullstack' = 'webFullstack',
  'java' = 'java',
  'qa' = 'qa',
  'design' = 'design',
  'mobile' = 'mobile',
  'mobileIOS' = 'mobileIOS',
  'mobileAndroid' = 'mobileAndroid',
  'analytic' = 'analytic',
  'qamanual' = 'qamanual',
  'qaauto' = 'qaauto',
}

export enum SpecializationNodeEnum {
  dev = 'dev',
  qa = 'qa',
  design = 'design',
}

export interface Competence {
  id: number;
  slug: string;
  title: string;
  description: string;
  complexity: number;
  level: Level;
  duration: Durations;
  isRequired: boolean;
  specialization: keyof typeof SpecializationNodeEnum;
  createdAt: string;
  updatedAt: string;
}

export const conditions = <const> ['AND', 'OR'];
export type Conditions = typeof conditions[number];

export interface CompetenceRelation {
  id: number;
  name: string;
  condition: Conditions;
}
