





























import { Component, Vue } from 'vue-property-decorator';
import { ExamModule } from '@/store/modules';

@Component({
  components: { },
})
export default class OpenExams extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'open-exams';

  @ExamModule.namespace.Action('getOpenExams')
  private getOpenExams: ExamModule['getOpenExams'];

  @ExamModule.namespace.Getter('openExamsSortedByExaminer')
  private exams: ExamModule['openExamsSortedByExaminer'];

  private mounted(): void {
    this.getOpenExams();
  }
}
