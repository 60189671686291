export const UserDepartmentEnum = <const>{
  Dunice4: 'Отдел 1',
  '2Floor': 'Отдел 2',
  DuniceAlliance: 'Отдел 4',
  EdCast: 'Отдел 3',
  PHP: 'Отдел 5',
  Java: 'Отдел 6',
  Design: 'Отдел Дизайна',
  HR: 'Отдел HR',
  QA: 'Отдел QA',
  Sales: 'Отдел продаж',
  'C*O': 'Директора',
  TrainingCenter: 'Академия',
  Other: 'Безымянный отдел',
  notAssigned: 'Без отдела',
  NvchDev1: 'Отдел 1Н',
  ShtDev1: 'Отдел 1Ш',
  MkpDev1: 'Отдел 1М',
  StvDev1: 'Отдел 1С',
};

export type UserDepartment = keyof typeof UserDepartmentEnum;
