



















import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import StarIcon from 'vue-material-design-icons/Star.vue';

import { UserModule, ConfigModule } from '@/store/modules';
import CompetenceInfoModal from '@/components/modals/CompetenceInfoModal.vue';
import ProfileBlock from '@/components/ProfileBlock.vue';
import { students } from '@/store/enums';

@Component({
  components: {
    StarIcon,
    CompetenceInfoModal,
    ProfileBlock,
  },
})
export default class DeveloperInfo extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'developer-info-page';

  @Prop({ default: null })
  private id!: string;

  @UserModule.namespace.State('developer')
  private developer: UserModule['developer'];

  @ConfigModule.namespace.State('isProfileBlockVisible')
  private isProfileBlockVisible: ConfigModule['isProfileBlockVisible'];

  private get routeLink(): { name: string; text: string } {
    const { name } = this.$route;
    if (name === 'DeveloperStatus' || name === 'DeveloperStatusMobile') {
      return { name: 'DeveloperStatistic', text: 'Сводная статистика' };
    }
    return { name: 'DeveloperStatus', text: 'Прогресс разработчика' };
  }

  private get showLevelTabs(): boolean {
    const { name } = this.$route;
    return name === 'DeveloperStatus';
  }

  @UserModule.namespace.Action('getOne')
  private getUser: UserModule['getOne'];

  private async beforeMount(): Promise<void> {
    await this.getUser(this.id);
    const hasAccess = this.developer?.roles.some(({ slug }) => students.includes(slug));
    if (!hasAccess) this.$router.push({ name: 'Index' });
  }
}
