





















import { Vue, Component, Prop } from 'vue-property-decorator';

import TotalDeveloperStatistic from '@/components/TotalDeveloperStatistic.vue';
import { UserModule } from '@/store/modules';

@Component({
  components: {
    TotalDeveloperStatistic,
  },
})

export default class StatisticTable extends Vue {
  @Prop({ default: () => ({}) }) private mods!: Modificator;

  private blockName: string = 'statistic-table';

  @UserModule.namespace.State('usersWithStatistic')
  private users: UserModule['usersWithStatistic'];
}
