






















































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import Menu from '@/components/Header/Menu.vue';
import ButtonLink from '@/components/Header/Link.vue';
import { ConfigModule, AuthModule } from '@/store/modules';
import BurgerMenuIcon from '@/components/Header/BurgerMenuIcon.vue';

@Component({
  components: { Menu, ButtonLink, BurgerMenuIcon },
})
export default class Header extends Vue {
  @Prop({ default: () => ({}) }) private mods!: Modificator;

  private blockName: string = 'header';

  @ConfigModule.namespace.State('appName') private appName: ConfigModule['appName'];

  @AuthModule.namespace.State('currentUser') private currentUser: AuthModule['currentUser'];

  @AuthModule.namespace.Getter('isStudent') private isStudent: AuthModule['isStudent'];

  @AuthModule.namespace.Getter('isAdmin') private isAdmin: AuthModule['isAdmin'];

  @AuthModule.namespace.Getter('isAdminStaff') private isAdminStaff: AuthModule['isAdminStaff'];

  @AuthModule.namespace.Getter('isAuthenticated') private isAuthenticated: AuthModule['isAuthenticated'];

  @AuthModule.namespace.Action('signOut') private signOut: AuthModule['signOut'];

  private get linksMods(): Modificator {
    const mods = {
      developer: this.isStudent ? '@' : '',
    };
    return mods;
  }

  private async logout() {
    await this.signOut();
    this.$router.push({ name: 'Index' });
  }

  private handleToggleSidebar() {
    document.dispatchEvent(new Event('toggle-sidebar'));
  }
}
