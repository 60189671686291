// eslint-disable-next-line import/prefer-default-export
// TODO: В будущем специализации брать из shared.
export const UserSpecializationEnum = <const>{
  qa: 'QA',
  qamanual: 'MT QA Engineer',
  qaauto: 'AT QA Engineer',
  java: 'Java',
  webFullstack: 'WebFullstack',
  mobile: 'Mobile',
  mobileIOS: 'Mobile IOS',
  mobileAndroid: 'Mobile Android',
  analytic: 'Analytic',
  csharp: 'C#',
  nodejs: 'Node JS',
  react: 'React',
};

export type UserSpecializations = keyof typeof UserSpecializationEnum;
