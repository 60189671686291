






















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: { },
})
export default class SearchInput extends Vue {
  @Prop({ default: '' })
  private placeholder: string;

  @Prop({ default: '' })
  private value: string;

  @Prop({ default: '' })
  private name: string;

  private blockName: string = 'search-input';
}
