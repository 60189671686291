









import { Component, Model, Vue } from 'vue-property-decorator';

import Select from '@/components/Select/Select.vue';
import { UserDepartment, UserDepartmentEnum } from '@/store/enums/department.enum';

export enum DepartmentOptionEnum {
  'all' = 1,
  'Dunice4',
  '2Floor',
  '6Department',
  'DuniceAlliance',
  'EdCast',
  'PHP',
  'Java',
  'Design',
  'HR',
  'QA',
  'NvchDev1',
  'TrainingCenter'
}

interface DepartmentsOption {
    id: number;
    label: typeof UserDepartmentEnum[UserDepartment] | 'Все отделы';
    value: UserDepartment | 'all';
    header: boolean;
}

export const departmentsOptions: DepartmentsOption[] = [
  {
    id: 1,
    label: 'Все отделы',
    value: 'all',
    header: true,
  },
  {
    id: 2,
    label: UserDepartmentEnum.Dunice4,
    value: 'Dunice4',
    header: false,
  },
  {
    id: 3,
    label: UserDepartmentEnum['2Floor'],
    value: '2Floor',
    header: false,
  },
  {
    id: 6,
    label: UserDepartmentEnum.EdCast,
    value: 'EdCast',
    header: false,
  },
  {
    id: 5,
    label: UserDepartmentEnum.DuniceAlliance,
    value: 'DuniceAlliance',
    header: false,
  },
  {
    id: 7,
    label: UserDepartmentEnum.PHP,
    value: 'PHP',
    header: false,
  },
  {
    id: 8,
    label: UserDepartmentEnum.Java,
    value: 'Java',
    header: false,
  },
  {
    id: 11,
    label: UserDepartmentEnum.QA,
    value: 'QA',
    header: false,
  },
  {
    id: 12,
    label: UserDepartmentEnum.NvchDev1,
    value: 'NvchDev1',
    header: false,
  },
  {
    id: 13,
    label: UserDepartmentEnum.ShtDev1,
    value: 'ShtDev1',
    header: false,
  },
  {
    id: 14,
    label: UserDepartmentEnum.MkpDev1,
    value: 'MkpDev1',
    header: false,
  },
  {
    id: 16,
    label: UserDepartmentEnum.StvDev1,
    value: 'StvDev1',
    header: false,
  },
  {
    id: 15,
    label: UserDepartmentEnum.TrainingCenter,
    value: 'TrainingCenter',
    header: false,
  },
];

@Component({
  components: { Select },
})
export default class DepartmentSelect extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'department-select';

  private options = departmentsOptions;

  @Model('change', { default: 1 })
  private departmentId!: number;
}
