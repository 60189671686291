import Vue from 'vue';
import VueGa from 'vue-gtag';

import router from '../routes';

Vue.use(
  VueGa,
  { config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID } },
  // auto-tracking all pages
  router,
);
