












import { Component, Vue } from 'vue-property-decorator';

import { ConfigModule, AuthModule } from '@/store/modules';
import Navbar from '@/components/Navbar/Navbar.vue';

@Component({
  components: { Navbar },
})
export default class Layout extends Vue {
  private blockName: string = 'layout';

  @ConfigModule.namespace.State('headerIsVisible')
  private headerIsVisible: ConfigModule['headerIsVisible'];

  @AuthModule.namespace.Getter('isAuthenticated') private isAuthenticated: AuthModule['isAuthenticated'];

  private get mods(): Modificator {
    return {
      header: this.headerIsVisible ? '' : 'collapsed',
    };
  }
}
