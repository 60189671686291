/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import globalSettings from './globalSettings';

export enum LogLevel {
  INFO = 0,
  WARN = 1,
  ERROR = 2,
  QUIET = 3,
}

const settings = globalSettings();

settings.LogLevel = LogLevel;

class Logger {
  public static get mode(): LogLevel {
    const value = Number(settings.currentLogLevel);
    if (Number.isNaN(value)) return LogLevel.INFO;
    return value;
  }

  public static set mode(value: LogLevel) {
    settings.currentLogLevel = value;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static info(place: string, message: any, ...optionalParams: any[]): void {
    if (this.mode <= LogLevel.INFO) console.info(place, message, ...optionalParams);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static warn(place: string, message: any, ...optionalParams: any[]): void {
    if (this.mode <= LogLevel.WARN) console.warn(place, message, ...optionalParams);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static error(place: string, message: any, ...optionalParams: any[]): void {
    if (this.mode <= LogLevel.ERROR) console.error(place, message, ...optionalParams);
  }

  private context: Console = null;

  constructor() {
    if ((console as any).context != null) this.context = (console as any).context();
    else this.context = console;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public info(place: string, message: any, ...optionalParams: any[]): void {
    if (Logger.mode <= LogLevel.INFO) this.context.info(place, message, ...optionalParams);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public warn(place: string, message: any, ...optionalParams: any[]): void {
    if (Logger.mode <= LogLevel.WARN) this.context.warn(place, message, ...optionalParams);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public error(place: string, message: any, ...optionalParams: any[]): void {
    if (Logger.mode <= LogLevel.ERROR) this.context.error(place, message, ...optionalParams);
  }
}

Logger.mode = process.env.NODE_ENV === 'production' ? LogLevel.QUIET : LogLevel.INFO;

export default Logger;
