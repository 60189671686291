
















import { Component, Vue, Watch } from 'vue-property-decorator';
import { AuthModule, ConfigModule, StatisticsModule } from '@/store/modules';
import UserWithLevel from '@/components/UserWithLevel.vue';
import AnalyticsList from '@/components/AnalyticsList.vue';
import AnalyticsListMobile from '@/components/AnalyticsListMobile.vue';
import DepartmentSelect, { DepartmentOptionEnum, departmentsOptions } from '@/components/DepartmentSelect.vue';
import date from '@/utils/filters/date';
import { GetAnalyticsQuery } from '@/store/models/Statistic';

@Component({
  components: {
    UserWithLevel,
    AnalyticsList,
    DepartmentSelect,
    AnalyticsListMobile,
  },
  filters: { date },
})
export default class Analytics extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'analytics';

  @StatisticsModule.namespace.Action('getAllAnalytics')
  private getAllAnalytics: StatisticsModule['getAllAnalytics'];

  @StatisticsModule.namespace.State('usersAnalyticsByDepartments')
  private analyticByDepartments: StatisticsModule['usersAnalyticsByDepartments'];

  @AuthModule.namespace.Getter('isAdminStaff')
  private isAdminStaff: AuthModule['isAdminStaff'];

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  private created(): void {
    this.getAllAnalytics();
  }

  private department = DepartmentOptionEnum.all;

  @Watch('department')
  watchDepartment(): void {
    const chosenDepartment = departmentsOptions.find(
      (departmentsOption) => departmentsOption.id === this.department,
    );
    const payload: GetAnalyticsQuery = {};
    if (chosenDepartment.id !== DepartmentOptionEnum.all) {
      payload.department = chosenDepartment.value;
    }
    this.getAllAnalytics(payload);
  }
}
