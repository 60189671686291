import {
  Module,
  VuexModule,
  Action, Mutation,
} from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { BindingHelpers } from 'vuex-class/lib/bindings';
import qs from 'qs';

import adapter from '@/store/adapter';
import Logger from '@/utils/Logger';
import {
  CompetenciesStatistic,
  ExaminersStatistic,
  GetAnalyticsQuery,
  UsersAnalyticByDepartments,
} from '@/store/models/Statistic';

export const MODULE_NAME = 'statistics';
export const getRoomNamespace = (): BindingHelpers => namespace(MODULE_NAME);

@Module({ stateFactory: true, namespaced: true })
export default class StatisticsModule extends VuexModule {
  private static _namespace: BindingHelpers = null;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getRoomNamespace();

    return this._namespace;
  }

  public usersAnalyticsByDepartments: UsersAnalyticByDepartments[] = [];

  public competenciesStatistics: CompetenciesStatistic[] = [];

  public examinersStatistic: ExaminersStatistic[] = [];

  @Mutation
  public setUsersAnalyticsByDepartments(usersAnalyticsByDepartments: UsersAnalyticByDepartments[]): void {
    this.usersAnalyticsByDepartments = usersAnalyticsByDepartments;
  }

  @Mutation
  public setCompetenciesStatistic(competenciesStatistics: CompetenciesStatistic[]): void {
    this.competenciesStatistics = competenciesStatistics;
  }

  @Mutation
  public setExaminersStatistic(examinersStatistic: ExaminersStatistic[]): void {
    this.examinersStatistic = examinersStatistic;
  }

  @Action
  public async getAllAnalytics(payload: GetAnalyticsQuery = {}): Promise<void> {
    try {
      const query = qs.stringify(payload, { skipNulls: true });
      const { data } = await adapter.get<UsersAnalyticByDepartments[]>(`/statistics/analytics?${query}`);
      this.context.commit('setUsersAnalyticsByDepartments', data);
    } catch (error) {
      Logger.error('Store::StatisticsModule:getAllAnalytics', error);
    }
  }

  @Action
  public async getCompetenciesStatistic(payload: {
    from?: string | null,
    to?: string | null,
  }): Promise<void> {
    try {
      const { data } = await adapter.get<CompetenciesStatistic[]>('/statistics/competencies', {
        params: payload,
      });
      this.context.commit('setCompetenciesStatistic', data);
    } catch (error) {
      Logger.error('Store::StatisticsModule:getCompetenciesStatistic', error);
    }
  }

  @Action
  public async getExaminersStatistic(): Promise<void> {
    try {
      const { data } = await adapter.get<ExaminersStatistic[]>('/statistics/examiners');
      this.context.commit('setExaminersStatistic', data);
    } catch (error) {
      Logger.error('Store::StatisticsModule:getExaminersStatistic', error);
    }
  }
}
