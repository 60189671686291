









import { Component, Model, Vue } from 'vue-property-decorator';

import Select from '@/components/Select/Select.vue';
import { getWeekOptions } from '@/utils';

export const weeksOptions = getWeekOptions();
export const currentWeekOptionIndex = 2;

@Component({
  components: { Select },
})
export default class WeekSelect extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'week-select';

  private options = weeksOptions;

  @Model('change', { default: weeksOptions[currentWeekOptionIndex].id })
  private weekNumber!: string | null;
}
