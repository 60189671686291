

















import {
  Component, Model, Prop, Vue,
} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AppRadio extends Vue {
  private get mods(): Modificator {
    return {
      disabled: this.disabled ? '@' : '',
    };
  }

  private blockName: string = 'radio';

  @Prop({ default: '' })
  private id!: string | number;

  @Prop()
  private name!: string;

  @Prop()
  private label!: string;

  @Prop()
  private inputValue!: string | number;

  @Prop({ default: false })
  private disabled: boolean;

  @Prop({ default: false })
  private checked: boolean;

  @Model('change')
  private readonly value!: string | number;
}
