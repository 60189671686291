













import { Component, Prop, Vue } from 'vue-property-decorator';

import HelpSvg from '@/components/graph-elements/HelpSvg.vue';

@Component({
  components: { HelpSvg },
})
export default class HelpPopover extends Vue {
  private blockName: string = 'help-popover';

  @Prop()
  private text!: string;

  @Prop({ default: 'bottom' })
  private placement?: 'top' | 'bottom' | 'left' | 'right';
}
