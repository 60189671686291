












































import { Component, Vue } from 'vue-property-decorator';
import { debounce } from '@/utils';

import WeekSelect from '@/components/WeekSelect.vue';
import { ExaminersModule } from '@/store/modules';
import AppChip from '@/components/core/AppChip.vue';
import SearchInput from '@/components/SearchInput.vue';

const REQUEST_DELAY = 300;

@Component({
  components: {
    WeekSelect,
    Chip: AppChip,
    SearchInput,
  },
})
export default class ExaminerCompetencies extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'examiners-competencies';

  @ExaminersModule.namespace.Action('getExaminersCompetencies')
  private getExaminersCompetencies: ExaminersModule['getExaminersCompetencies'];

  @ExaminersModule.namespace.State('examinersCompetencies')
  private examinersCompetencies: ExaminersModule['examinersCompetencies'];

  public name = '';

  created(): void {
    this.retrieveExaminers();
  }

  private onChangeSearch(value: string) {
    this.name = value;
    this.debouncedRetrieve();
  }

  private debouncedRetrieve = debounce(this.retrieveExaminers, REQUEST_DELAY);

  private onClear() {
    this.name = '';
    this.retrieveExaminers();
  }

  private retrieveExaminers(): void {
    this.getExaminersCompetencies(this.name);
  }
}
