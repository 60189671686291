
























import {
  Component, Model, Prop, Vue,
} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AppInput extends Vue {
  private get mods(): Modificator {
    return {
      fullwidth: this.fullwidth ? '@' : '',
    };
  }

  private blockName: string = 'input';

  @Model('change')
  private readonly value!: string

  @Prop({ default: '' })
  private label: string

  @Prop()
  private type: 'email' | 'number' | 'password' | 'tel' | 'text' | 'time' | 'url' | 'textarea'

  @Prop({ default: false })
  private fullwidth: boolean
}
