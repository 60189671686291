























































































import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import { AuthModule, ConfigModule, UserModule } from '@/store/modules';
import { User } from '@/store/models/User';
import date from '@/utils/filters/date';
import departments from '@/utils/filters/departments';
import { debounce, isOldSpecialization } from '@/utils';
import SearchInput from '@/components/SearchInput.vue';
import Select from '@/components/Select/Select.vue';
import { UserDepartment, UserDepartmentEnum } from '@/store/enums/department.enum';
import { RolesEnum } from '@/../private_modules/dunice-space-shared/types';

const REQUEST_DELAY = 300;

interface DeveloperListFilter {
  search?: string;
  level?: string;
  department?: string;
  roles?: string;
}

interface DepartmentsOption {
    id: number;
    label: typeof UserDepartmentEnum[UserDepartment] | 'Все отделы';
    value: UserDepartment | 'all';
    header: boolean;
}
@Component({
  components: {
    SearchInput,
    Select,
  },
  filters: { date, departments },
})
export default class DeveloperListIndex extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'developer-list-page';

  private searchValue: string = '';

  private readonly departmentsOptions: DepartmentsOption[] = [
    {
      id: 1,
      label: 'Все отделы',
      value: 'all',
      header: true,
    },
    {
      id: 2,
      label: UserDepartmentEnum.Dunice4,
      value: 'Dunice4',
      header: false,
    },
    {
      id: 3,
      label: UserDepartmentEnum['2Floor'],
      value: '2Floor',
      header: false,
    },
    {
      id: 6,
      label: UserDepartmentEnum.EdCast,
      value: 'EdCast',
      header: false,
    },
    {
      id: 5,
      label: UserDepartmentEnum.DuniceAlliance,
      value: 'DuniceAlliance',
      header: false,
    },
    {
      id: 7,
      label: UserDepartmentEnum.PHP,
      value: 'PHP',
      header: false,
    },
    {
      id: 8,
      label: UserDepartmentEnum.Java,
      value: 'Java',
      header: false,
    },
    {
      id: 11,
      label: UserDepartmentEnum.NvchDev1,
      value: 'NvchDev1',
      header: false,
    },
    {
      id: 12,
      label: UserDepartmentEnum.ShtDev1,
      value: 'ShtDev1',
      header: false,
    },
    {
      id: 13,
      label: UserDepartmentEnum.MkpDev1,
      value: 'MkpDev1',
      header: false,
    },
    {
      id: 15,
      label: UserDepartmentEnum.StvDev1,
      value: 'StvDev1',
      header: false,
    },
    {
      id: 14,
      label: UserDepartmentEnum.TrainingCenter,
      value: 'TrainingCenter',
      header: false,
    },
  ];

  private readonly levelsOptions = [
    {
      id: 1,
      label: 'Все уровни',
      value: 'all',
      header: true,
    },
    {
      id: 2,
      label: 'Стажер',
      value: 'TRAINEE',
      header: false,
    },
    {
      id: 3,
      label: 'Junior',
      value: 'JUNIOR',
      header: false,
    },
    {
      id: 4,
      label: 'Middle',
      value: 'MIDDLE',
      header: false,
    },
    {
      id: 5,
      label: 'Senior',
      value: 'SENIOR',
      header: false,
    },
  ];

  @Prop({ default: null })
  private level!: string;

  @Prop({ default: null })
  private department!: string;

  @Prop({ default: null })
  private search!: string;

  private currentDepartmentId: number = 1;

  private currentLevelId: number = 1;

  private debouncedGetDevelopers: (filters: DeveloperListFilter) => void;

  @UserModule.namespace.Action('getDevelopers')
  private getDevelopers: UserModule['getDevelopers'];

  @UserModule.namespace.State('usersByDepartmentsList')
  private usersByDepartmentsList: UserModule['usersByDepartmentsList'];

  @AuthModule.namespace.State('currentUser') private currentUser: AuthModule['currentUser'];

  @ConfigModule.namespace.State('isMobile') private isMobile: ConfigModule['isMobile'];

  @AuthModule.namespace.Getter('isAdminStaff')
  private isAdminStaff: AuthModule['isAdminStaff'];

  private created(): void {
    if (this.search != null) this.searchValue = this.search;
    if (this.level != null) {
      this.currentLevelId = this.levelsOptions.find(({ value }) => value === this.level).id;
    }
    if (this.department != null) {
      this.currentDepartmentId = this.departmentsOptions.find(({ value }) => value === this.department).id;
    }

    this.debouncedGetDevelopers = debounce(this.getDevelopers, REQUEST_DELAY);
    this.fetchData();
  }

  @Watch('level')
  private levelChanged() {
    if (this.level != null) {
      this.currentLevelId = this.levelsOptions.find(({ value }) => value === this.level).id;
    } else this.currentLevelId = 1;
    this.fetchData();
  }

  @Watch('department')
  private departmentChanged() {
    if (this.department != null) {
      this.currentDepartmentId = this.departmentsOptions.find(({ value }) => value === this.department).id;
    } else this.currentDepartmentId = 1;
    this.fetchData();
  }

  @Watch('search')
  private searchChanged() {
    if (this.search != null) this.searchValue = this.search;
    else this.searchValue = '';
    this.fetchData();
  }

  private buildFilters(): Partial<DeveloperListFilter> {
    const filter: Partial<DeveloperListFilter> = { };
    if (this.searchValue !== '') {
      filter.search = this.searchValue;
    }

    if (this.currentLevelId !== 1) {
      filter.level = this.levelsOptions.find(({ id }) => id === this.currentLevelId).value;
    }

    if (this.currentDepartmentId !== 1) {
      filter.department = this.departmentsOptions.find(({ id }) => id === this.currentDepartmentId).value;
    }

    return filter;
  }

  private fetchData() {
    this.debouncedGetDevelopers({ ...this.buildFilters() });
  }

  private applyFilter() {
    this.$router.push({ name: 'DeveloperList', query: this.buildFilters() });
  }

  private onFilterSelect({ id, name }: { id: number; name: string}): void {
    if (name === 'level') {
      if (this.currentLevelId === id) return;
      this.currentLevelId = id;
    }
    if (name === 'department') {
      if (this.currentDepartmentId === id) return;
      this.currentDepartmentId = id;
    }
    this.applyFilter();
  }

  private onChangeSearch(value: string) {
    this.searchValue = value;
    this.applyFilter();
  }

  private onClear() {
    this.searchValue = '';
    this.applyFilter();
  }

  private get searchPlaceholder() {
    const hasAccess = this.currentUser.roles.some(
      ({ slug }) => slug === RolesEnum.admin || slug === RolesEnum.unitlead,
    );
    if (hasAccess && this.currentDepartmentId === 1) return 'Поиск по всем отделам';
    return 'Поиск по отделу';
  }

  onClickUser = (user: User): void => {
    if (isOldSpecialization(user.userSpecialization.version)) {
      this.$router.push({ name: 'DeveloperStatus', params: { id: user.id } });
    } else {
      window.open(`${process.env.VUE_APP_NEW_RISE_UP_URL}/developer-info/${user.id}`, 'blank');
    }
  }

  checkSpecialization(user: User): boolean {
    return isOldSpecialization(user.userSpecialization.version);
  }
}
