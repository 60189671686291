



























































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import StarIcon from 'vue-material-design-icons/Star.vue';

import { User } from '@/store/models/User';
import { ConfigModule } from '@/store/modules';
import CompetenceInfoModal from '@/components/modals/CompetenceInfoModal.vue';
import date from '@/utils/filters/date';
import { isOldSpecialization } from '@/utils';

@Component({
  components: {
    StarIcon,
    CompetenceInfoModal,
  },
  filters: {
    date,
  },
})
export default class ProfileBlock extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'profile-block';

  @Prop({ default: null })
  private id!: string;

  @Prop({ default: {} })
  private user!: User;

  @Prop({ default: {} })
  private routeLink!: { name: string; text: string };

  @ConfigModule.namespace.State('headerIsVisible')
  private headerIsVisible: ConfigModule['headerIsVisible'];

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  get showCompetenceMapRoute(): boolean {
    return isOldSpecialization(this.user.userSpecialization.version);
  }
}
