













import { Vue, Component, Prop } from 'vue-property-decorator';

import TotalDeveloperStatisticCard from '@/components/TotalDeveloperStatisticCard.vue';
import { UserModule } from '@/store/modules';

@Component({
  components: {
    TotalDeveloperStatisticCard,
  },
})

export default class StatisticCards extends Vue {
  @Prop({ default: () => ({}) }) private mods!: Modificator;

  private blockName: string = 'statistic-cards';

  @UserModule.namespace.State('usersWithStatistic')
  private users: UserModule['usersWithStatistic'];
}
