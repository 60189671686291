











































import { Component, Vue } from 'vue-property-decorator';
import { AuthModule } from '@/store/modules';

@Component({
  components: { },
})
export default class StatisticList extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'statistic-list-page';

  @AuthModule.namespace.Getter('isAdminStaff')
  private isAdminStaff: AuthModule['isAdminStaff'];
}
