import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { BindingHelpers } from 'vuex-class/lib/bindings';
import { namespace } from 'vuex-class';
import { AxiosResponse } from 'axios';

import {
  ExaminerBusyness,
  ExaminerCompetencies,
  ExaminersExamsCountStatistic,
} from '@/store/models/Examiners';
import { Exam } from '@/store/models/Exam';
import adapter from '@/store/adapter';
import Logger from '@/utils/Logger';

export const MODULE_NAME = 'examiners';
export const getExaminersNamespace = (): BindingHelpers => namespace(MODULE_NAME);

@Module({ stateFactory: true, namespaced: true })
export default class Examiners extends VuexModule {
  private static _namespace: BindingHelpers = null;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getExaminersNamespace();

    return this._namespace;
  }

  public examinersBusyness: ExaminerBusyness[] = [];

  public examinersCompetencies: ExaminerCompetencies[] = [];

  public examsCountStatistic: ExaminersExamsCountStatistic[] = [];

  @Mutation
  public setExaminersBusyness(data: ExaminerBusyness[]): void {
    this.examinersBusyness = data;
  }

  @Mutation
  public setExaminersCompetencies(data: ExaminerCompetencies[]): void {
    this.examinersCompetencies = data;
  }

  @Mutation
  public setExamsCountStatistic(data: ExaminersExamsCountStatistic[]): void {
    this.examsCountStatistic = data;
  }

  @Action
  public async getExaminersBusyness(weekNumber: string): Promise<void> {
    try {
      const response = await adapter.get<Exam[], AxiosResponse<Exam[]>>('/examiners/statistic/busyness', {
        params: { weekNumber },
      });
      this.context.commit('setExaminersBusyness', response.data);
    } catch (error) {
      Logger.error('Store::ExamModule:getExaminersBusyness', error);
    }
  }

  @Action
  public async getExaminersCompetencies(name: string): Promise<void> {
    try {
      const response = await adapter.get<Exam[], AxiosResponse<Exam[]>>('/examiners/statistic/competencies', {
        params: { name },
      });
      this.context.commit('setExaminersCompetencies', response.data);
    } catch (error) {
      Logger.error('Store::ExamModule:getExaminersCompetencies', error);
    }
  }

  @Action
  public async getExaminersExamCountStatistic(year: number): Promise<void> {
    try {
      const response = await adapter.get<Exam[], AxiosResponse<Exam[]>>('/examiners/statistic/exams/count', {
        params: { year },
      });
      this.context.commit('setExamsCountStatistic', response.data);
    } catch (error) {
      Logger.error('Store::ExamModule:getExaminersExamCountStatistic', error);
    }
  }
}
