








































import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules';
import Select from '@/components/Select/Select.vue';
import { SpecializationEnum } from '@/store/enums';

const specializationOptions = [
  {
    id: 'webFullstack',
    label: 'Web Fullstack',
    header: false,
  },
  {
    id: 'java',
    label: 'Java',
    header: false,
  },
  {
    id: 'qamanual',
    label: 'MT QA Engineer',
    header: false,
  },
  {
    id: 'qaauto',
    label: 'AT QA Engineer',
    header: false,
  },
  {
    id: 'design',
    label: 'Design',
    header: false,
  },
  {
    id: 'mobile',
    label: 'Mobile',
    header: false,
  },
  {
    id: 'mobileIOS',
    label: 'Mobile IOS',
    header: false,
  },
  {
    id: 'mobileAndroid',
    label: 'Mobile Android',
    header: false,
  },
  {
    id: 'analytic',
    label: 'Analytic',
    header: false,
  },
];

@Component({
  components: { Select },
})
export default class ManageUsers extends Vue {
  private blockName: string = 'manage-users';

  private specializationOptions = specializationOptions;

  @UserModule.namespace.Action('getUsersWithoutProfile')
  private getUsersWithoutProfile: UserModule['getUsersWithoutProfile'];

  @UserModule.namespace.Action('createProfile')
  private createProfile: UserModule['createProfile'];

  @UserModule.namespace.State('usersWithoutDepartment')
  private users: UserModule['usersWithoutDepartment'];

  created(): void {
    this.getUsersWithoutProfile();
  }

  onChangeSpecialization({ id }: { id: SpecializationEnum }, userId: string): void {
    this.createProfile({ specializationType: id, userId });
  }
}
