





















import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { DateTime } from 'luxon';

import { UserModule, ConfigModule } from '@/store/modules';
import StatisticTable from '@/components/StatisticTable.vue';
import StatisticCards from '@/components/StatisticCards.vue';
import Select from '@/components/Select/Select.vue';
import HelpPopover from '@/components/core/HelpPopover.vue';

interface UserStatisticListFilter {
  from: string;
  to: string;
}

@Component({
  components: {
    Select,
    StatisticTable,
    StatisticCards,
    HelpPopover,
  },
  filters: {},
})
export default class StatisticListIndex extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'statistic-list';

  private readonly periodOptions = [
    {
      id: 1,
      label: 'Текущий расчетный период',
      value: 'billingPeriod',
      header: false,
    },
    {
      id: 2,
      label: 'Предыдущий расчетный период',
      value: 'prevBillingPeriod',
      header: false,
    },
  ];

  private currentPeriodId: number = 1;

  @UserModule.namespace.Action('getAllStatistic')
  private getAllStatistic: UserModule['getAllStatistic'];

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  private created(): void {
    const { from, to } = this.$route.query as { from?: string; to?: string };
    let query;
    if (to != null || from != null) {
      this.currentPeriodId = null;
      query = { from, to };
    } else {
      query = this.buildFilters();
    }
    this.getAllStatistic(query);
  }

  private buildFilters(): Partial<UserStatisticListFilter> {
    const filter: Partial<UserStatisticListFilter> = {};
    const today = DateTime.local();
    let from = DateTime.local()
      .set({ day: 25 })
      .startOf('day');
    if (today.day < 25) {
      from = from.minus({ months: 1 });
    }
    let to = DateTime.local()
      .set({ day: 24 })
      .endOf('day');
    if (today.day >= 25) {
      to = to.plus({ months: 1 });
    }
    if (this.currentPeriodId === 2) {
      from = from.minus({ months: 1 });
      to = to.minus({ months: 1 });
    }

    filter.from = from.toISO();
    filter.to = to.toISO();
    return filter;
  }

  private applyFilter() {
    this.$router.push({ name: 'StatisticList', query: this.buildFilters() });
    this.fetchData();
  }

  private fetchData(): void {
    this.getAllStatistic(this.buildFilters());
  }

  private onPeriodSelect({ id }: { id: number }): void {
    if (this.currentPeriodId === id) return;
    this.currentPeriodId = id;
    this.applyFilter();
  }
}
