








import { Component, Prop, Vue } from 'vue-property-decorator';

import { ExamStatus } from '@/store/enums';

@Component({
  components: {},
})
export default class AppStatusIcon extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'status-icon';

  @Prop()
  status!: ExamStatus;
}
