


















import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import { Competence } from '@/store/models/Competence';
import {
  AuthModule,
  UserModule,
  ConfigModule,
  SpecializationModule,
} from '@/store/modules';

import LevelTabs from '@/components/LevelTabs.vue';
import CompetenceInfoModal from '@/components/modals/CompetenceInfoModal.vue';
import SpecializationLine from '@/components/SpecializationLine.vue';
import TryExamModal from '@/components/modals/TryExamModal.vue';
import ExamCreatedModal from '@/components/modals/ExamCreatedModal.vue';
import { students } from '@/store/enums';
import { RiseUpProfile } from '@/../private_modules/dunice-space-shared/types';
import { isOldSpecialization } from '@/utils';

const TAG_REGEXP = /<.+?>/gm;

@Component({
  components: {
    ExamCreatedModal,
    TryExamModal,
    CompetenceInfoModal,
    LevelTabs,
    SpecializationLine,
  },
})
export default class Status extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'status-page';

  @Prop({ default: null })
  private id!: string;

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  @AuthModule.namespace.State('currentUser')
  private currentUser: AuthModule['currentUser'];

  @UserModule.namespace.State('developer')
  private developer: UserModule['developer'];

  @AuthModule.namespace.State('isDeveloper')
  private currentUserIsDeveloper: AuthModule['isDeveloper'];

  private activeLevel: string = null;

  private get isCurrentUser(): boolean {
    if (this.developer === null) return true;
    return this.developer.id === this.currentUser.id;
  }

  private get user(): RiseUpProfile {
    if (this.isCurrentUser) return this.currentUser;
    return this.developer;
  }

  @SpecializationModule.namespace.Action('getRiseProgress')
  private getSpecializationListWithCompetences: SpecializationModule['getRiseProgress'];

  @SpecializationModule.namespace.State('specializationList')
  private lines: SpecializationModule['specializationList'];

  private async beforeMount(): Promise<void> {
    if (this.isMobile) {
      if (this.isCurrentUser) this.$router.push({ name: 'MyStatusMobile' });
      else this.$router.push({ name: 'DeveloperStatusMobile' });
    }
  }

  private async mounted(): Promise<void> {
    if (this.isMobile) return;
    if (!this.isCurrentUser) {
      const hasAccess = this.developer.roles.some(({ slug }) => students.includes(slug));
      if (!hasAccess) this.$router.push({ name: 'Index' });
    }
    this.activeLevel = this.user.level;
    await this.getSpecializationListWithCompetences({ user: this.user, level: this.activeLevel });

    if (!isOldSpecialization(this.user.userSpecialization.version)) {
      if (this.currentUser.id !== this.user.id) {
        window.open(`${process.env.VUE_APP_NEW_RISE_UP_URL}/developer-info/${this.user.id}`, 'blank');
        this.$router.back();
      } else {
        window.open(`${process.env.VUE_APP_NEW_RISE_UP_URL}/my-progress`, 'self');
      }
    }
  }

  private async updateLevel(level: string): Promise<void> {
    this.activeLevel = level;
    await this.getSpecializationListWithCompetences({ user: this.user, level });
  }

  @Watch('isMobile')
  private isMobileChanged(value: boolean) {
    if (value) {
      if (this.isCurrentUser) this.$router.push({ name: 'MyStatusMobile' });
      else this.$router.push({ name: 'DeveloperStatusMobile' });
    }
  }

  private openDetailModal(competence: Competence, competenceIsAllowed: boolean): void {
    // if (
    //   this.isCurrentUser && !competenceIsAllowed
    // ) return;

    this.$modal.show('competence-info', {
      competence,
      user: this.user,
      isCurrentUser: this.isCurrentUser,
      isAllowed: competenceIsAllowed,
    });

    const { name, id } = competence;
    const cardName = name.replace(TAG_REGEXP, ' ');
    if (this.isCurrentUser) {
      this.$gtag.event(
        'card-open',
        {
          event_category: 'competence',
          event_label: `${id} | ${cardName}`,
        },
      );
    }
  }
}
