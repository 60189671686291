







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CompetenceDuration extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'competence-duration';

  @Prop({ default: null })
  private duration: number;
}
