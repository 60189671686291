












































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';

import { Exam } from '@/store/models/Exam';
import CompetenceName from '@/components/CompetenceName.vue';

@Component({
  components: { CompetenceName },
})
export default class PassingExamListItemMobile extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'exam-list-item-mobile';

  @Prop()
  private exam!: Exam

  private get frame(): string {
    if (this.exam.frames[0]) {
      const frame = DateTime.fromISO(this.exam.frames[0].frame);

      return frame.toFormat('dd.LL.yyyy \'в\' HH:mm');
    }
    return null;
  }

  private get examiner(): string {
    const { examiner } = this.exam;
    if (examiner != null) {
      return `${examiner.firstName} ${examiner.lastName}`;
    }
    return null;
  }

  private get showStatus() {
    return ['sick', 'rejected', 'timeOver', 'passed', 'failed'].includes(this.exam.status);
  }
}
