









































import { Component, Prop, Vue } from 'vue-property-decorator';

import { Exam } from '@/store/models/Exam';
import { AuthModule, ConfigModule, ExamModule } from '@/store/modules';
import SetExamDateTimeModal from '@/components/modals/SetExamDateTimeModal.vue';
import CurrentExamListItem from '@/components/CurrentExamList/CurrentExamListItem.vue';
import CurrentExamListItemMobile from '@/components/CurrentExamList/CurrentExamListItemMobile.vue';
import SetExamStatusModal from '@/components/modals/SetExamStatusModal.vue';

@Component({
  components: {
    SetExamStatusModal,
    CurrentExamListItemMobile,
    CurrentExamListItem,
    SetExamDateTimeModal,
  },
})
export default class CurrentExamList extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'current-exam-list';

  @Prop()
  private exams!: Exam[];

  @Prop({ default: true })
  private editable: boolean;

  @Prop({ default: true })
  private showDepartment: boolean;

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  @ExamModule.namespace.State('currentExamsFilter')
  private currentExamsFilter: ExamModule['currentExamsFilter'];

  @ExamModule.namespace.Action('getAll')
  private getAll: ExamModule['getAll'];

  @AuthModule.namespace.Getter('isAdminStaff')
  private isAdminStaff: AuthModule['isAdminStaff'];

  private openSetDateTimeModal(exam: Exam) {
    this.$modal.show('set-exam-date-time-modal', { exam });
  }
}
