









import { Component, Model, Vue } from 'vue-property-decorator';

import Select from '@/components/Select/Select.vue';

const OPTIONS = [
  {
    id: 'all',
    header: false,
    label: 'Все принимающие',
    value: 'all',
  },
  {
    id: 'assigned',
    header: false,
    label: 'Назначенные',
    value: 'assigned',
  },
  {
    id: 'notAssigned',
    header: false,
    label: 'Не назначенные',
    value: 'notAssigned',
  },
];

@Component({
  components: { Select },
})
export default class ExamStatusSelect extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'exam-status-select';

  private options = OPTIONS;

  @Model('change', { default: '1' })
  private status!: string | null;
}
