











import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: { },
})
export default class Link extends Vue {
  @Prop({ default: () => ({}) }) private mods!: Modificator;

  @Prop({ default: () => ({}) }) private to!: string;

  @Prop({ default: () => ({}) }) private activeClass!: string;

  private blockName: string = 'link';
}
