

















import { Component, Prop, Vue } from 'vue-property-decorator';

import { User } from '@/store/models/User';

@Component({
  components: {},
})
export default class UserWithLevel extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'user-with-level';

  @Prop()
  private user!: User;

  @Prop({ default: () => false })
  private isLink!: boolean;

  @Prop({ default: () => false })
  private showSpecialization!: boolean;

  @Prop({ default: 'left' })
  private levelAlign!: 'left' | 'right';

  private get alignment() {
    const { levelAlign } = this;
    return {
      [levelAlign]: '@',
    };
  }
}
