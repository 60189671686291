import {
  Module, VuexModule, Action, Mutation,
} from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { BindingHelpers } from 'vuex-class/lib/bindings';
import axios, { AxiosResponse } from 'axios';

import adapter, { BackEndError } from '@/store/adapter';
import Logger from '@/utils/Logger';
import {
  AssignExaminerActionPayload,
  CancelExamStatusPayload,
  CreateExamPostData,
  Exam,
  ExamAnalysis,
  ExamQueryFilters,
  SetExamStatusPayload,
} from '@/store/models/Exam';
import { ExamStatusTransitions } from '@/store/enums/exam.enums';
import { UserDepartment } from '@/store/enums';
// TODO: We need to fix it.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { currentWeekOptionIndex, weeksOptions } from '@/components/WeekSelect.vue';
import { getWeeksRange } from '@/utils';
import { DateTime } from 'luxon';

export const MODULE_NAME = 'exams';
export const getExamNamespace = (): BindingHelpers => namespace(MODULE_NAME);

type CurrentExamFilters = Pick<ExamQueryFilters, 'departments' | 'weekNumber' | 'assignee'>;

type ExamModuleActions = 'getOne' | 'getAll' | 'create' | 'setStatus' | 'assignExaminer';
type ExamModuleError = Record<ExamModuleActions, BackEndError>;

@Module({ stateFactory: true, namespaced: true })
export default class ExamModule extends VuexModule {
  private static _namespace: BindingHelpers = null;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getExamNamespace();

    return this._namespace;
  }

  public exams: Exam[] = [];

  public examAnalysis: ExamAnalysis = null;

  public examHash: Record<string, Exam> = {};

  public currentExamsFilter: CurrentExamFilters = {
    departments: [],
    weekNumber: weeksOptions[currentWeekOptionIndex].id as string,
    assignee: 'all',
  }

  public error: ExamModuleError = {
    getOne: null,
    getAll: null,
    create: null,
    setStatus: null,
    assignExaminer: null,
  };

  @Mutation
  public setError(error: Partial<ExamModuleError>): void {
    this.error = { ...this.error, ...error };
  }

  @Mutation
  public setExams(exams: Exam[]): void {
    this.exams = exams;
    for (const exam of exams) this.examHash[exam.id] = exam;
  }

  @Mutation
  public setExam(exam: Exam): void {
    const index = this.exams.findIndex(({ id }) => id === exam.id);
    this.exams.splice(index, 1, exam);
    this.examHash[exam.id] = exam;
  }

  @Mutation
  public updateCurrentExamsDepartmentFilter(value: UserDepartment[]): void {
    this.currentExamsFilter.departments = value;
  }

  @Mutation
  public updateCurrentExamsWeekNumberFilter(value: null | string): void {
    this.currentExamsFilter.weekNumber = value;
  }

  @Mutation
  public updateCurrentExamsAssigneeFilter(value: string): void {
    this.currentExamsFilter.assignee = value;
  }

  @Mutation
  public setExamsAnalysis(examAnalysis: ExamAnalysis): void {
    this.examAnalysis = examAnalysis;
  }

  @Action
  public async getOne(examId: string): Promise<Exam> {
    try {
      const response = await adapter.get<Exam, AxiosResponse<Exam>>(`/exams/${examId}`, {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      const exam = response.data;
      this.context.commit('setExam', exam);
      return exam;
    } catch (error) {
      Logger.error('Store::ExamModule:getOne', error);
      return null;
    }
  }

  @Action
  public async getAll(): Promise<Exam[]> {
    try {
      const params = { ...this.currentExamsFilter };
      if (params.assignee === 'all') delete params.assignee;

      const response = await adapter.get<Exam[], AxiosResponse<Exam[]>>('/exams', {
        headers: this.context.rootGetters['auth/accessHeader'],
        params,
      });
      const exams = response.data;
      this.context.commit('setExams', exams);
      return exams;
    } catch (error) {
      Logger.error('Store::ExamModule:ExamModule', error);
      return null;
    }
  }

  @Action
  public async getOpenExams(): Promise<Exam[]> {
    try {
      const { year, weekNumber } = DateTime.local();
      const response = await adapter.get<Exam[], AxiosResponse<Exam[]>>('/exams', {
        headers: this.context.rootGetters['auth/accessHeader'],
        params: {
          maxWeekNumber: `${year}.${(weekNumber - 1).toString().padStart(2, '0')}`,
          status: ['initiated', 'assigned', 'ready'],
        },
      });
      const exams = response.data;
      this.context.commit('setExams', exams);
      return exams;
    } catch (error) {
      Logger.error('Store::ExamModule:ExamModule', error);
      return null;
    }
  }

  @Action
  public async getExamAnalysis(): Promise<ExamAnalysis> {
    try {
      const response = await adapter.get<ExamAnalysis, AxiosResponse<ExamAnalysis>>('/exams/analysis', {
        headers: this.context.rootGetters['auth/accessHeader'],
        params: {
          departments: this.currentExamsFilter.departments,
          ...getWeeksRange(new Date()),
        },
      });
      const examAnalysis = response.data;
      this.context.commit('setExamsAnalysis', examAnalysis);
      return examAnalysis;
    } catch (error) {
      Logger.error('Store::ExamModule:ExamModule', error);
      return null;
    }
  }

  @Action
  public async getMy(weekNumber: number | string): Promise<Exam[]> {
    try {
      const response = await adapter.get<Exam[]>('/exams/my', {
        headers: this.context.rootGetters['auth/accessHeader'],
        params: { weekNumber },
      });
      const exams = response.data;
      this.context.commit('setExams', exams);
      return exams;
    } catch (error) {
      Logger.error('Store::ExamModule:ExamModule', error);
      return null;
    }
  }

  @Action
  public async create(data: CreateExamPostData): Promise<Exam> {
    try {
      this.context.commit('setError', { create: null });
      const response = await adapter.post<Exam>('/exams', data, {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) this.context.commit('setError', { create: error.response.data });
      Logger.error('Store::ExamModule:create', error);
      return null;
    }
  }

  @Action
  public async setStatus(data: SetExamStatusPayload): Promise<Exam> {
    try {
      this.context.commit('setError', { setStatus: null });
      const { id, status } = data;
      const transition = ExamStatusTransitions[status as keyof typeof ExamStatusTransitions];
      const response = await adapter.patch<Exam, AxiosResponse<Exam>>(`/exams/${id}/${transition}`, data, {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) this.context.commit('setError', { setStatus: error.response.data });
      Logger.error('Store::ExamModule:setStatus', error);
      return null;
    }
  }

  @Action
  public async cancelExam(data: CancelExamStatusPayload): Promise<Exam> {
    try {
      this.context.commit('setError', { setStatus: null });
      const { id } = data;
      const response = await adapter.patch<Exam, AxiosResponse<Exam>>(`/exams/${id}/cancel`, data, {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) this.context.commit('setError', { setStatus: error.response.data });
      Logger.error('Store::ExamModule:setStatus', error);
      return null;
    }
  }

  @Action
  public async assignExaminer({ examId, examinerId }: AssignExaminerActionPayload): Promise<Exam> {
    try {
      const response = await adapter.patch<Exam>(`/exams/${examId}/assign`, { examinerId }, {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      return response.data;
    } catch (error) {
      Logger.error('Store::ExamDatetimeFrameModule:assignExaminer', error);
      return null;
    }
  }

  public get openExamsSortedByExaminer(): Exam[] {
    return this.exams.sort((a, b) => {
      if (a.examiner == null && b.examiner == null) return b.weekNumber - a.weekNumber;
      if (a.examiner == null) return 1;
      if (b.examiner == null) return -1;
      if (a.examiner.lastName === b.examiner.lastName) return b.weekNumber - a.weekNumber;
      return a.examiner?.lastName.localeCompare(b.examiner?.lastName);
    });
  }
}
