export default (func: cb, delay: number, immediate?: boolean): cb => {
  let timeout: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(
      () => {
        timeout = null;
        if (!immediate) func(...args);
      },
      delay,
    );

    if (callNow) func(...args);
  };
};
