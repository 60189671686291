import Vue from 'vue';
import Vuex from 'vuex';

import {
  AuthModule as auth,
  ConfigModule as config,
  SpecializationModule as specialization,
  CompetenceModule as competence,
  UserModule as user,
  ExamModule as exams,
  RoomModule as room,
  FrameModule as frame,
  UserUnavailabilityModule as userUnavailability,
  DepartmentsModule as departments,
  ExaminersModule as examiners,
  StatisticsModule as statistics,
} from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    config,
    specialization,
    competence,
    user,
    exams,
    room,
    frame,
    userUnavailability,
    departments,
    examiners,
    statistics,
  },
});
