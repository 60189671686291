import {
  Action,
  Module,
  VuexModule,
} from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { BindingHelpers } from 'vuex-class/lib/bindings';

import adapter from '@/store/adapter';
import Logger from '@/utils/Logger';

export const MODULE_NAME = 'competence';
export const getCompetenceNamespace = (): BindingHelpers => namespace(MODULE_NAME);

@Module({ stateFactory: true, namespaced: true })
export default class CompetenceModule extends VuexModule {
  private static _namespace: BindingHelpers = null;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getCompetenceNamespace();

    return this._namespace;
  }

  @Action
  async approveCompetence(payload: { userId: string; competenceId: string }): Promise<void> {
    try {
      await adapter.post('/user-competencies', payload);
    } catch (error) {
      Logger.error('Store::Competence:approveCompetence', error);
    }
  }
}
