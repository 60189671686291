

























import { Vue, Component, Prop } from 'vue-property-decorator';

import { Competence } from '@/store/models/Competence';
import { User } from '@/store/models/User';
import CompetenceInfo from '@/components/CompetenceInfo.vue';
import { ExamModule } from '@/store/modules';

interface RecieveDataEvent {
  params: {
    competence: Competence;
    user: User;
    isCurrentUser: boolean,
    isAllowed: boolean
  }
}

@Component({
  components: { CompetenceInfo },
})
export default class CompetenceInfoModal extends Vue {
  @Prop({ default: () => ({}) }) private mods!: Modificator;

  private blockName: string = 'competence-info-modal';

  @ExamModule.namespace.Mutation('setError')
  private setError: ExamModule['setError'];

  private competence: Competence = null;

  private user: User = null;

  private isCurrentUser: boolean = true;

  private isAllowed: boolean = false;

  private receiveData(event: RecieveDataEvent) {
    this.competence = event.params.competence;
    this.user = event.params.user;
    this.isCurrentUser = event.params.isCurrentUser;
    this.isAllowed = event.params.isAllowed;
  }

  private closed(): void {
    this.setError({ setStatus: null });
  }
}
