











































































import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';

import { Specialization } from '@/store/models/Specialization';
import { Competence } from '@/store/models/Competence';
import {
  AuthModule,
  ConfigModule,
  SpecializationModule,
  UserModule,
} from '@/store/modules';

import LevelTabs from '@/components/LevelTabs.vue';
import CompetenceCard from '@/components/CompetenceCard.vue';
import CompetenceInfo from '@/components/CompetenceInfo.vue';
import TryExamModal from '@/components/modals/TryExamModal.vue';
import ExamCreatedModal from '@/components/modals/ExamCreatedModal.vue';
import mround from '@/utils/filters/mround';
import { bitwiseConditionCarried } from '@/utils/bitwiseCondition';
import { RiseUpProfile } from '@/../private_modules/dunice-space-shared/types';
import { students } from '@/store/enums';

const TAG_REGEXP = /<.+?>/gm;

type AvailableRoute = 'DeveloperStatus' | 'MyStatus' | 'DeveloperStatusMobile' | 'MyStatusMobile';
type GetRouteNameFunction = (isMobile: boolean, isCurrentUser: boolean) => AvailableRoute;

const getRouteName: GetRouteNameFunction = bitwiseConditionCarried<AvailableRoute>(
  ['DeveloperStatus', 'MyStatus', 'DeveloperStatusMobile', 'MyStatusMobile'],
);

@Component({
  components: {
    ExamCreatedModal,
    TryExamModal,
    ChevronLeftIcon,
    ChevronRightIcon,
    CompetenceInfo,
    CompetenceCard,
    LevelTabs,
  },
  filters: { mround },
})
export default class StatusMobile extends Vue {
  @Prop({ default: false })
  private isCurrentUser!: boolean;

  private get mods(): Modificator {
    return {
      'my-progress': this.isCurrentUser ? '@' : '',
      state: this.routeState === 'SPECIALIZATION' ? 'specialization' : '',
    };
  }

  private blockName: string = 'status-page';

  private routeState: 'INITIAL' | 'SPECIALIZATION' | 'COMPETENCE' = 'INITIAL';

  private activeSpecialization: Specialization = null;

  private activeCompetence: Competence = null;

  private activeCompetenceIsAllowed: boolean = false;

  @Prop({ default: null })
  private specializationId!: string;

  @Prop({ default: null })
  private competenceId!: string;

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  @AuthModule.namespace.State('currentUser')
  private currentUser: AuthModule['currentUser'];

  @AuthModule.namespace.State('isDeveloper')
  private currentUserIsDeveloper: AuthModule['isDeveloper'];

  private activeLevel: string = null;

  private lines: Specialization[] = null;

  @UserModule.namespace.State('developer')
  private developer: UserModule['developer'];

  private get user(): RiseUpProfile {
    if (this.isCurrentUser) return this.currentUser;
    return this.developer;
  }

  @ConfigModule.namespace.Mutation('switchOffHeader')
  private switchOffHeader: ConfigModule['switchOffHeader'];

  @ConfigModule.namespace.Mutation('switchOnHeader')
  private switchOnHeader: ConfigModule['switchOnHeader'];

  @ConfigModule.namespace.Mutation('switchOffProfileBlock')
  private switchOffProfileBlock: ConfigModule['switchOffProfileBlock'];

  @ConfigModule.namespace.Mutation('switchOnProfileBlock')
  private switchOnProfileBlock: ConfigModule['switchOnProfileBlock'];

  @SpecializationModule.namespace.Action('getRiseProgress')
  private getSpecializationListWithCompetences: SpecializationModule['getRiseProgress'];

  @UserModule.namespace.Action('getOne')
  private getUser: UserModule['getOne'];

  private async beforeMount(): Promise<void> {
    if (!this.isMobile) {
      if (this.isCurrentUser) this.$router.push({ name: 'MyStatus' });
      else this.$router.push({ name: 'DeveloperStatus' });
    }
  }

  private async mounted(): Promise<void> {
    if (!this.isMobile) return;
    if (!this.isCurrentUser) {
      const hasAccess = this.developer.roles.some(({ slug }) => students.includes(slug));
      if (!hasAccess) this.$router.push({ name: 'Index' });
    }
    this.activeLevel = this.user.level;
    this.lines = await this.getSpecializationListWithCompetences({ user: this.user, level: this.activeLevel });
    this.rebuildRouteState();
  }

  private beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.switchOnHeader();
    this.switchOnProfileBlock();
    next();
  }

  private rebuildRouteState() {
    if (this.routeState === 'INITIAL') {
      if (this.specializationId != null) {
        this.switchOffHeader();
        this.switchOffProfileBlock();
        this.activeSpecialization = this.lines.find(({ id }) => this.specializationId === id);

        if (this.competenceId == null) this.routeState = 'SPECIALIZATION';
        else {
          this.activeCompetence = this.activeSpecialization.competencies.find(({ id }) => this.competenceId === id);
          this.routeState = 'COMPETENCE';
        }
      }
      return;
    }

    if (this.routeState === 'SPECIALIZATION') {
      if (this.specializationId == null) {
        this.switchOnHeader();
        this.switchOnProfileBlock();
        this.activeSpecialization = null;
        this.routeState = 'INITIAL';
      } else {
        if (this.activeSpecialization.id !== this.specializationId) {
          this.activeSpecialization = this.lines.find(({ id }) => this.specializationId === id);
        }
        if (this.competenceId != null) {
          this.activeCompetence = this.activeSpecialization.competencies.find(({ id }) => this.competenceId === id);
          this.routeState = 'COMPETENCE';
        }
      }
      return;
    }

    if (this.routeState === 'COMPETENCE') {
      if (this.specializationId == null) {
        this.switchOnHeader();
        this.switchOnProfileBlock();
        this.activeSpecialization = null;
        this.activeCompetence = null;
        this.routeState = 'INITIAL';
      } else if (this.competenceId == null) {
        if (this.activeSpecialization.id !== this.specializationId) {
          this.activeSpecialization = this.lines.find(({ id }) => this.specializationId === id);
        }
        this.activeCompetence = null;
        this.routeState = 'SPECIALIZATION';
      } else {
        if (this.activeSpecialization.id !== this.specializationId) {
          this.activeSpecialization = this.lines.find(({ id }) => this.specializationId === id);
        }
        if (this.activeCompetence.id !== this.competenceId) {
          this.activeCompetence = this.activeSpecialization.competencies.find(({ id }) => this.competenceId === id);
        }
      }
      return;
    }
  }

  @Watch('specializationId')
  private specializationIdChanged() {
    this.rebuildRouteState();
  }

  @Watch('competenceId')
  private competenceIdChanged() {
    this.rebuildRouteState();
  }

  private goToStatus(query: { specialization?: string; competence?: string }) {
    this.$router.push({
      name: getRouteName(this.isMobile, this.isCurrentUser),
      query,
    });
  }

  private forward() {
    if (this.routeState === 'INITIAL') {
      this.goToStatus({ specialization: this.activeSpecialization.id });
      return;
    }
    if (this.routeState === 'SPECIALIZATION') {
      this.goToStatus({ specialization: this.activeSpecialization.id, competence: this.activeCompetence.id });
      return;
    }
  }

  private back() {
    if (this.routeState === 'SPECIALIZATION') {
      this.goToStatus({ });
      return;
    }
    if (this.routeState === 'COMPETENCE') {
      this.goToStatus({ specialization: this.activeSpecialization.id });
      return;
    }
  }

  private async examPassed(): Promise<void> {
    this.back();
    this.lines = await this.getSpecializationListWithCompetences({ user: this.user, level: this.activeLevel });
  }

  private async updateLevel(level: string): Promise<void> {
    this.activeLevel = level;
    this.lines = await this.getSpecializationListWithCompetences({ user: this.user, level });
  }

  @Watch('isMobile')
  private isMobileChanged(value: boolean) {
    if (!value) {
      this.switchOnHeader();
      this.switchOnProfileBlock();
      if (this.isCurrentUser) this.$router.push({ name: 'MyStatus' });
      else this.$router.push({ name: 'DeveloperStatus' });
    }
  }

  private competenceIsAllowed(competence: Competence, index: number) {
    if (competence.passed) return true;
    if (index === 0) return true;
    const prevCompetence = this.activeSpecialization.competencies[index - 1];
    if (index > 0 && prevCompetence.passed) return true;
    if (index === 1 && prevCompetence.duration === 20 && competence.duration === 20) return true;
    const secondBeforeCurrentCompetence = this.activeSpecialization.competencies[index - 2];
    return (
      index > 1
      && secondBeforeCurrentCompetence.passed
      && prevCompetence.duration === 20
      && competence.duration === 20
    );
  }

  private activateSpecialization(specialization: Specialization): void {
    if (specialization == null) {
      this.back();
      return;
    }

    this.activeSpecialization = specialization;
    this.forward();
  }

  private activateCompetence(competence: Competence, competenceIsAllowed: boolean = false): void {
    if (competence == null) {
      this.back();
      return;
    }

    // if (
    //   this.isCurrentUser && !competenceIsAllowed
    // ) return;

    this.activeCompetence = competence;
    this.activeCompetenceIsAllowed = competenceIsAllowed;

    const { name, id } = competence;
    const cardName = name.replace(TAG_REGEXP, ' ');

    if (this.isCurrentUser) {
      this.$gtag.event(
        'card-open',
        {
          event_category: 'competence',
          event_label: `${id} | ${cardName}`,
        },
      );
    }

    this.forward();
  }
}
