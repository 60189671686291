var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm._f("bemMods")(_vm.blockName,_vm.mods)},[_c('div',{class:_vm._f("bemElement")(_vm.blockName,'cap')}),_c('div',{class:_vm._f("bemElement")(_vm.blockName,'cap')}),_c('div',{class:_vm._f("bemMods")(_vm._f("bemElement")(_vm.blockName,'button'),{
      state: _vm.current === 'TRAINEE' ? 'active' : ''
    }),on:{"click":function($event){return _vm.changeLevel('TRAINEE')}}},[_vm._v(" Trainee ")]),_c('div',{class:_vm._f("bemMods")(_vm._f("bemElement")(_vm.blockName,'button'),{
      state: _vm.current === 'JUNIOR' ? 'active' : ''
    }),on:{"click":function($event){return _vm.changeLevel('JUNIOR')}}},[_vm._v(" Junior ")]),_c('div',{class:_vm._f("bemMods")(_vm._f("bemElement")(_vm.blockName,'button'),{
      state: _vm.current === 'MIDDLE' ? 'active' : ''
    }),on:{"click":function($event){return _vm.changeLevel('MIDDLE')}}},[_vm._v(" Middle ")]),_c('div',{class:_vm._f("bemMods")(_vm._f("bemElement")(_vm.blockName,'button'),{
      state: _vm.current === 'SENIOR' ? 'active' : ''
    }),on:{"click":function($event){return _vm.changeLevel('SENIOR')}}},[_vm._v(" Senior ")])])}
var staticRenderFns = []

export { render, staticRenderFns }