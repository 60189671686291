






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AppButton extends Vue {
  private get mods(): Modificator {
    return {
      color: this.color,
      size: this.size,
      fullWidth: this.fullWidth ? '@' : '',
      disabled: this.disabled ? '@' : '',
    };
  }

  private blockName: string = 'button';

  @Prop()
  private text!: string;

  @Prop({ default: 'normal' })
  private color: 'normal' | 'primary';

  @Prop({ default: false })
  private disabled: boolean;

  @Prop({ default: false })
  private fullWidth: boolean;

  @Prop({ default: 'normal' })
  private size: 'normal' | 'small';
}
