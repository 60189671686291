import {
  Module, VuexModule, Action, Mutation,
} from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { BindingHelpers } from 'vuex-class/lib/bindings';
import qs from 'qs';

import adapter, { BackEndError } from '@/store/adapter';
import Logger from '@/utils/Logger';
import {
  BindExamData,
  ExamDatetimeFrame,
  ExamDatetimeFramePostData,
  ExamDatetimeFrameQuery, ExamDatetimeFrameUpdate,
} from '@/store/models/ExamDatetimeFrame';
import axios from 'axios';

export const MODULE_NAME = 'frame';
export const getExamDatetimeFrameNamespace = (): BindingHelpers => namespace(MODULE_NAME);

/*
  нельзя так делать, ибо циклическая зависимость
  type ExamDatetimeFrameModuleError = Record<KeyOfByType<ExamDatetimeFrameModule, GeneralActionInteface>, BackEndError>;
*/

type ExamDatetimeFrameModuleActions = 'getOne' | 'getAll' | 'create' | 'update' | 'reset' | 'bindExam';
type ExamDatetimeFrameModuleError = Record<ExamDatetimeFrameModuleActions, BackEndError>;

@Module({ stateFactory: true, namespaced: true })
export default class ExamDatetimeFrameModule extends VuexModule {
  private static _namespace: BindingHelpers = null;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getExamDatetimeFrameNamespace();

    return this._namespace;
  }

  public list: ExamDatetimeFrame[] = [];

  public hash: Record<string, ExamDatetimeFrame> = {};

  public error: ExamDatetimeFrameModuleError = {
    getOne: null,
    getAll: null,
    create: null,
    update: null,
    reset: null,
    bindExam: null,
  };

  @Mutation
  public setError(error: Partial<ExamDatetimeFrameModuleError>): void {
    this.error = { ...this.error, ...error };
  }

  @Mutation
  public setExamDatetimeFrames(examDatetimeFrames: ExamDatetimeFrame[]): void {
    this.list = examDatetimeFrames;
    for (const examDatetimeFrame of examDatetimeFrames) this.hash[examDatetimeFrame.id] = examDatetimeFrame;
  }

  @Mutation
  public setExamDatetimeFrame(examDatetimeFrame: ExamDatetimeFrame): void {
    const index = this.list.findIndex(({ id }) => id === examDatetimeFrame.id);
    this.list.splice(index, 1, examDatetimeFrame);
    this.hash[examDatetimeFrame.id] = examDatetimeFrame;
  }

  @Action
  public async getOne(examDatetimeFrameId: string): Promise<ExamDatetimeFrame> {
    try {
      const response = await adapter.get<ExamDatetimeFrame>(`/exam-datetime-frame/${examDatetimeFrameId}`, {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      const examDatetimeFrame = response.data;
      this.context.commit('setExamDatetimeFrame', examDatetimeFrame);
      return examDatetimeFrame;
    } catch (error) {
      Logger.error('Store::ExamDatetimeFrameModule:getOne', error);
      return null;
    }
  }

  @Action
  public async getAll(data: ExamDatetimeFrameQuery): Promise<ExamDatetimeFrame[]> {
    try {
      const query = qs.stringify(data, { skipNulls: true });
      const response = await adapter.get<ExamDatetimeFrame[]>(`/exam-datetime-frame?${query}`, {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      const examDatetimeFrame = response.data;
      this.context.commit('setExamDatetimeFrames', examDatetimeFrame);
      return examDatetimeFrame;
    } catch (error) {
      Logger.error('Store::ExamDatetimeFrameModule:getAll', error);
      return null;
    }
  }

  @Action
  public async create(data: ExamDatetimeFramePostData): Promise<ExamDatetimeFrame> {
    try {
      const response = await adapter.post<ExamDatetimeFrame>('/exam-datetime-frame', data, {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      const examDatetimeFrame = response.data;
      this.context.commit('setExamDatetimeFrame', examDatetimeFrame);
      return examDatetimeFrame;
    } catch (error) {
      Logger.error('Store::ExamDatetimeFrameModule:create', error);
      return null;
    }
  }

  @Action
  public async update(frame: ExamDatetimeFrameUpdate): Promise<ExamDatetimeFrame> {
    try {
      this.context.commit('setError', { update: null });
      const updatedFrame = {
        id: frame.id,
        examId: frame.examId,
      };
      const response = await adapter.patch<ExamDatetimeFrame>(
        `/exam-datetime-frame/${updatedFrame.id}`,
        updatedFrame,
        {
          headers: this.context.rootGetters['auth/accessHeader'],
        },
      );
      const examDatetimeFrame = response.data;
      this.context.commit('setExamDatetimeFrame', examDatetimeFrame);
      this.context.dispatch('exams/getMy', {}, { root: true });
      return examDatetimeFrame;
    } catch (error) {
      if (axios.isAxiosError(error)) this.context.commit('setError', { update: error.response.data });
      Logger.error('Store::ExamDatetimeFrameModule:update', error);
      return null;
    }
  }

  @Action
  public async reset(frameId: string): Promise<ExamDatetimeFrame> {
    try {
      const response = await adapter.patch<ExamDatetimeFrame>(
        `/exam-datetime-frame/${frameId}/reset`,
        null,
        {
          headers: this.context.rootGetters['auth/accessHeader'],
        },
      );
      const examDatetimeFrame = response.data;
      this.context.commit('setExamDatetimeFrame', examDatetimeFrame);
      return examDatetimeFrame;
    } catch (error) {
      Logger.error('Store::ExamDatetimeFrameModule:create', error);
      return null;
    }
  }

  @Action
  public async bindExam(bindExamData: BindExamData): Promise<void> {
    try {
      this.context.commit('setError', { bindExam: null });
      await adapter.patch<ExamDatetimeFrame>(
        '/exam-datetime-frame/bind-exam',
        bindExamData,
        {
          headers: this.context.rootGetters['auth/accessHeader'],
        },
      );
    } catch (error) {
      if (axios.isAxiosError(error)) this.context.commit('setError', { bindExam: error.response.data });
      Logger.error('Store::ExamDatetimeFrameModule:bindExam', error);
    }
  }

  @Action
  public clearStateErrors(actions: ExamDatetimeFrameModuleActions[]): void {
    const errorState = Object.fromEntries(actions.map((action) => [action, null]));
    this.context.commit('setError', errorState);
  }
}
