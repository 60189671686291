import {
  Module, VuexModule, Action, Mutation,
} from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { BindingHelpers } from 'vuex-class/lib/bindings';
import qs from 'qs';

import adapter from '@/store/adapter';
import Logger from '@/utils/Logger';
import { UserUnavailability, UserUnavailabilityPostData } from '@/store/models/UserUnavailability';
import { AxiosResponse } from 'axios';
import { UnavailabilityProp } from '@/components/unavailability/Unavailability.vue';

export const MODULE_NAME = 'userUnavailability';
export const getUserUnavailabilityNamespace = (): BindingHelpers => namespace(MODULE_NAME);

@Module({ stateFactory: true, namespaced: true })
export default class UserUnavailabilityModule extends VuexModule {
  private static _namespace: BindingHelpers = null;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getUserUnavailabilityNamespace();

    return this._namespace;
  }

  public list: Partial<UserUnavailability>[] = [];

  public hash: Record<string, Partial<UserUnavailability>> = {};

  public get unavailability(): Partial<UnavailabilityProp>[] {
    return this.list.map((item) => ({
      ...item,
      from: item.from != null ? new Date(item.from) : null,
      to: item.to != null ? new Date(item.to) : null,
    }));
  }

  @Mutation
  public setUserUnavailabilities(userUnavailabilities: Partial<UserUnavailability>[]): void {
    this.list = userUnavailabilities;
    for (const userUnavailability of userUnavailabilities) this.hash[userUnavailability.id] = userUnavailability;
  }

  @Mutation
  public setUserUnavailability(userUnavailability: UserUnavailability): void {
    const index = this.list.findIndex(({ id }) => id === userUnavailability.id);
    this.list.splice(index, 1, userUnavailability);
    this.hash[userUnavailability.id] = userUnavailability;
  }

  @Mutation
  public updateUnavailability(data: Partial<UserUnavailabilityPostData> & { index: number; }): void {
    const { index, ...rest } = data;
    this.list = this.list.map((item, i) => {
      if (index !== i) return item;
      return { ...item, ...rest };
    });
  }

  @Mutation
  public addUnavailability(data: Partial<UserUnavailability>): void {
    this.list.push(data);
  }

  @Action
  public async getOne(userUnavailabilityId: string): Promise<UserUnavailability> {
    try {
      const response = await adapter.get<UserUnavailability, AxiosResponse<UserUnavailability>>(
        `/user-unavailability/${userUnavailabilityId}`,
        {
          headers: this.context.rootGetters['auth/accessHeader'],
        },
      );
      const userUnavailability = response.data;
      this.context.commit('setUserUnavailability', userUnavailability);
      return userUnavailability;
    } catch (error) {
      Logger.error('Store::UserUnavailabilityModule:getOne', error);
      return null;
    }
  }

  @Action
  public async getAll(): Promise<UserUnavailability[]> {
    try {
      const response = await adapter.get<UserUnavailability[], AxiosResponse<UserUnavailability[]>>(
        '/user-unavailability',
        {
          headers: this.context.rootGetters['auth/accessHeader'],
        },
      );
      const userUnavailabilities = response.data;
      this.context.commit('setUserUnavailabilities', userUnavailabilities);
      return userUnavailabilities;
    } catch (error) {
      Logger.error('Store::UserUnavailabilityModule:getAll', error);
      return null;
    }
  }

  @Action
  public async getAllByWeek(data?: { from: string; to: string; }): Promise<UserUnavailability[]> {
    try {
      const query = qs.stringify(data, { skipNulls: true });
      const response = await adapter.get<UserUnavailability[], AxiosResponse<UserUnavailability[]>>(
        `/user-unavailability?${query}`,
        {
          headers: this.context.rootGetters['auth/accessHeader'],
        },
      );
      const userUnavailabilities = response.data;
      this.context.commit('setUserUnavailabilities', userUnavailabilities);
      return userUnavailabilities;
    } catch (error) {
      Logger.error('Store::UserUnavailabilityModule:getAll', error);
      return null;
    }
  }

  @Action
  public async create(data: UserUnavailabilityPostData): Promise<UserUnavailability> {
    try {
      const response = await adapter.post<UserUnavailability>('/user-unavailability', data, {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      return response.data;
    } catch (error) {
      Logger.error('Store::UserUnavailabilityModule:create', error);
      throw error;
    }
  }

  @Action
  public async bulkCreateOrUpdate(data: UserUnavailabilityPostData[]): Promise<void> {
    try {
      const result = await Promise.all(data.map((item) => this.context.dispatch('create', item)));
      this.context.commit('setUserUnavailabilities', result);
    } catch (error) {
      Logger.error('Store::UserUnavailabilityModule:bulkCreateOrUpdate', error);
      throw error;
    }
  }

  @Action
  public async delete(index: number): Promise<void> {
    try {
      const unavailability = this.list[index];
      if (unavailability.id != null) {
        await adapter.delete<void>(`/user-unavailability/${unavailability.id}`, {
          headers: this.context.rootGetters['auth/accessHeader'],
        });
      }
      const newList = [...this.list];
      newList.splice(index, 1);
      this.context.commit('setUserUnavailabilities', newList);
    } catch (error) {
      Logger.error('Store::UserUnavailabilityModule:delete', error);
      throw error;
    }
  }
}
