import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import store from '@/store';

import indexPageGuard from '@/guards/indexPageGuard';
import Logger from '@/utils/Logger';
import { RolesEnum } from '@/../private_modules/dunice-space-shared/types';

import Analytics from './Analytics.vue';
import DeveloperList from './DeveloperList.vue';
import DeveloperListIndex from './DeveloperListIndex.vue';
import StatisticList from './StatisticList.vue';
import StatisticListIndex from './StatisticListIndex.vue';
import DeveloperInfo from './DeveloperInfo.vue';
import Status from './Status.vue';
import StatusMobile from './StatusMobile.vue';
import Waiting from './Waiting.vue';
import PageNotFound from './PageNotFound.vue';
import DeveloperStatistic from './DeveloperStatistic.vue';
import ExamList from './ExamList.vue';
import ExamListByWeek from './ExamListByWeek.vue';
import MyPassingExams from './MyPassingExams.vue';
import MyExaminingExams from './MyExaminingExams.vue';
import ExamsAnalysisPivot from './ExamsAnalysisPivot.vue';
import ManageUsers from './ManageUsers.vue';
import ExaminersBusyness from './ExaminersBusyness.vue';
import ExaminerCompetencies from './ExaminerCompetencies.vue';
import ExaminersList from './ExaminersList.vue';
import CompetenciesStatistics from './CompetenciesStatistics/CompetenciesStatistics.vue';
import ExaminersStatistic from './ExaminersStatistic.vue';
import OpenExams from './OpenExams.vue';
import ExaminersExamsCountStatistic from './ExaminersExamsCountStatistic.vue';

Vue.use(VueRouter);

const accessToRoleOnly = (role: RolesEnum) => ({
  role,
  roleStrictCheck: true,
});

const accessToRoleOrAbove = (role: RolesEnum) => ({
  role,
});

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Index',
    beforeEnter: indexPageGuard,
  },
  {
    path: '/waiting',
    name: 'Waiting',
    component: Waiting,
    meta: accessToRoleOnly(RolesEnum.notApproved),
  },
  {
    path: '/my-progress',
    name: 'MyStatus',
    component: Status,
    props: { isCurrentUser: true },
    meta: accessToRoleOrAbove(RolesEnum.developer),
  },
  {
    path: '/my-progress-mobile',
    name: 'MyStatusMobile',
    component: StatusMobile,
    meta: accessToRoleOrAbove(RolesEnum.developer),
    props: ({ query }) => ({
      isCurrentUser: true,
      specializationId: query.specialization,
      competenceId: query.competence,
    }),
  },
  {
    path: '/developers',
    component: DeveloperList,
    meta: accessToRoleOrAbove(RolesEnum.manager),
    children: [
      {
        path: '',
        name: 'DeveloperList',
        component: DeveloperListIndex,
        meta: accessToRoleOrAbove(RolesEnum.manager),
        props: ({ query }) => ({ ...query }),
      },
      {
        path: ':id',
        component: DeveloperInfo,
        meta: accessToRoleOrAbove(RolesEnum.manager),
        props: true,
        children: [
          {
            path: '',
            name: 'DeveloperStatistic',
            component: DeveloperStatistic,
            meta: accessToRoleOrAbove(RolesEnum.manager),
            props: true,
          },
          {
            path: 'progress',
            name: 'DeveloperStatus',
            component: Status,
            meta: accessToRoleOrAbove(RolesEnum.manager),
            props: true,
          },
          {
            path: 'progress-mobile',
            name: 'DeveloperStatusMobile',
            component: StatusMobile,
            meta: accessToRoleOrAbove(RolesEnum.manager),
            props: ({ query }) => ({ specializationId: query.specialization, competenceId: query.competence }),
          },
        ],
      },
    ],
  },
  {
    path: '/statistic',
    component: StatisticList,
    meta: accessToRoleOrAbove(RolesEnum.manager),
    children: [
      {
        path: '',
        redirect: 'progress',
      },
      {
        path: 'progress',
        name: 'StatisticList',
        component: StatisticListIndex,
        meta: accessToRoleOrAbove(RolesEnum.manager),
        props: ({ query }) => ({ ...query }),
      },
      {
        path: 'analytics',
        name: 'StatisticAnalytics',
        component: Analytics,
        meta: accessToRoleOrAbove(RolesEnum.manager),
        props: ({ query }) => ({ ...query }),
      },
      {
        path: 'competencies',
        name: 'CompetenciesStatistics',
        component: CompetenciesStatistics,
        meta: accessToRoleOrAbove(RolesEnum.manager),
        props: ({ query }) => ({ ...query }),
      },

      {
        path: 'examiners',
        name: 'ExaminersStatistic',
        component: ExaminersStatistic,
        meta: accessToRoleOrAbove(RolesEnum.manager),
        props: ({ query }) => ({ ...query }),
      },
    ],
  },
  {
    path: '/exams',
    name: 'Exams',
    component: ExamList,
    meta: accessToRoleOrAbove(RolesEnum.developer),
    props: ({ query }) => ({ ...query }),
    children: [
      {
        path: 'analysis-pivot',
        name: 'ExamsAnalysisPivot',
        component: ExamsAnalysisPivot,
        meta: accessToRoleOrAbove(RolesEnum.manager),
        props: ({ query }) => ({ ...query }),
      },
      {
        path: 'by-weeks',
        name: 'ExamsByWeek',
        component: ExamListByWeek,
        meta: accessToRoleOrAbove(RolesEnum.manager),
        props: ({ query }) => ({ ...query }),
      },
      {
        path: 'passing-exams',
        name: 'PassingExams',
        component: MyPassingExams,
        meta: accessToRoleOrAbove(RolesEnum.developer),
        props: ({ query }) => ({ ...query }),
      },
      {
        path: 'examining-exams',
        name: 'ExaminingExams',
        component: MyExaminingExams,
        meta: accessToRoleOrAbove(RolesEnum.manager),
        props: ({ query }) => ({ ...query }),
      },
      {
        path: 'open-exams',
        name: 'OpenExams',
        component: OpenExams,
        meta: accessToRoleOrAbove(RolesEnum.manager),
        props: ({ query }) => ({ ...query }),
      },
    ],
  },
  {
    path: '/examiners',
    name: 'Examiners',
    component: ExaminersList,
    meta: accessToRoleOrAbove(RolesEnum.unitlead),
    props: ({ query }) => ({ ...query }),
    children: [
      {
        path: 'busyness',
        name: 'ExaminersBusyness',
        component: ExaminersBusyness,
        meta: accessToRoleOrAbove(RolesEnum.unitlead),
        props: ({ query }) => ({ ...query }),
      },
      {
        path: 'competencies',
        name: 'ExaminersCompetencies',
        component: ExaminerCompetencies,
        meta: accessToRoleOrAbove(RolesEnum.unitlead),
        props: ({ query }) => ({ ...query }),
      },
      {
        path: 'exams-count',
        name: 'ExaminersExamsCountStatistic',
        component: ExaminersExamsCountStatistic,
        meta: accessToRoleOrAbove(RolesEnum.unitlead),
        props: ({ query }) => ({ ...query }),
      },
    ],
  },
  {
    path: '/manage-users',
    component: ManageUsers,
    meta: accessToRoleOrAbove(RolesEnum.admin),
  },
  {
    path: '*',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.role === 'ALL' || to.meta.role == null) return next();
  if (!store.getters['auth/hasAccess'](to.meta.role, to.meta.roleStrictCheck)) {
    if (from.name == null) return next({ name: 'Index' });
    return next(false);
  }
  return next();
});

router.onError((error) => {
  Logger.error('Router::', error);
});

export default router;
