











































import { Component, Prop, Vue } from 'vue-property-decorator';

import { UsersAnalyticByDepartments } from '@/store/models/Statistic';
import UserWithLevel from '@/components/UserWithLevel.vue';
import date from '@/utils/filters/date';

@Component({
  components: { UserWithLevel },
  filters: { date },
})
export default class AnalyticsList extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'analytics-list';

  @Prop() private data: UsersAnalyticByDepartments[];
}
