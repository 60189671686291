












































import { Component, Vue } from 'vue-property-decorator';

import { User } from '@/store/models/User';
import CongratulationsBackgroundSvg from '@/components/graph-elements/CongratulationsBackgroundSvg.vue';
import CongratulationsBackgroundMobileSvg from '@/components/graph-elements/CongratulationsBackgroundMobileSvg.vue';
import {
  AuthModule,
  ConfigModule,
  UserModule,
} from '@/store/modules';

@Component({
  components: {
    CongratulationsBackgroundSvg,
    CongratulationsBackgroundMobileSvg,
  },
})
export default class CongratulationsModal extends Vue {
  private blockName = 'congratulations-modal';

  @AuthModule.namespace.State('currentUser') private currentUser: User;

  @ConfigModule.namespace.State('isMobile')
  private isMobile: boolean;

  @UserModule.namespace.Action('updateNeedCongratulate')
  private updateNeedCongratulate: () => Promise<void>;

  private async onClose(): Promise<void> {
    await this.updateNeedCongratulate();
    this.$modal.hide('congratulations');
  }
}
