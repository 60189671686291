import { DateTime } from 'luxon';

import { SelectOption } from '@/components/Select/SelectOption';

/**
 * @param formattedWeekNumber - 2021.01
 * @param startWeekDate
 * @param endWeekDate
 */
function getWeekLabel(formattedWeekNumber: string, startWeekDate?: string, endWeekDate?: string) {
  const [, weekNumber] = formattedWeekNumber.split('.');
  return `${weekNumber} неделя (${startWeekDate && endWeekDate ? `${startWeekDate} - ${endWeekDate}` : 'текущая'})`;
}

export default (): SelectOption[] => {
  const result: SelectOption[] = [{
    id: null,
    label: 'Все недели',
    header: true,
  }];
  const today = DateTime.local();
  const startOfCurrentWeek = today.startOf('week');
  const endOfCurrentWeek = today.endOf('week');
  const currentWeekNumber = today.toFormat('yyyy.WW');

  const startOfNextWeek = startOfCurrentWeek.plus({ week: 1 }).toFormat('dd.MM');
  const endOfNextWeek = endOfCurrentWeek.plus({ week: 1 }).toFormat('dd.MM');
  const nextWeekNumber = today.plus({ week: 1 }).toFormat('yyyy.WW');

  result.push({
    id: nextWeekNumber,
    label: getWeekLabel(nextWeekNumber, startOfNextWeek, endOfNextWeek),
    header: false,
  }, {
    id: currentWeekNumber,
    label: getWeekLabel(currentWeekNumber),
    header: false,
  });

  for (let i = 1; i < 8; i += 1) {
    const start = startOfCurrentWeek.minus({ week: i });
    const startWeekDate = start.toFormat('dd.MM');
    const endWeekDate = endOfCurrentWeek.minus({ week: i }).toFormat('dd.MM');
    const weekNumber = start.toFormat('yyyy.WW');

    result.push({
      id: weekNumber,
      label: getWeekLabel(weekNumber, startWeekDate, endWeekDate),
      header: false,
    });
  }

  return result;
};
