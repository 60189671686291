





















import { Vue, Component } from 'vue-property-decorator';

import { Exam } from '@/store/models/Exam';
import ExamInfo from '@/components/ExamInfo.vue';
import { User } from '@/store/models/User';

interface EventData {
  params: {
    user: Pick<User, 'firstName' | 'lastName' | 'level' | 'id'>,
    exam: Omit<Exam, 'student' | 'studentUnavailability' | 'lastAttemptNumber'>;
  }
}

@Component({
  components: { ExamInfo },
})
export default class ExamInfoModal extends Vue {
  private exam: Omit<Exam, 'student' | 'studentUnavailability' | 'lastAttemptNumber'> = null;

  private user: Pick<User, 'firstName' | 'lastName' | 'level' | 'id'> = null;

  private examiner: Pick<User, 'firstName' | 'lastName' | 'id'> = null;

  private receiveData(event: EventData) {
    const {
      exam,
      user,
    } = event.params;
    this.exam = exam;
    this.user = user;
    this.examiner = exam.examiner;
  }
}
