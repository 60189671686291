












import {
  Component, Model, Vue, Prop,
} from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

@Component({
  components: { DatePicker },
  inheritAttrs: false,
})
export default class AppDatetimePicker extends Vue {
  private blockName: string = 'mx-input';

  @Model('change')
  private value: string | number;

  @Prop({ default: () => false })
  private isInvalid!: boolean;

  @Prop({ default: () => true })
  private isClearable!: boolean;
}
