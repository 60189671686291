import {
  Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { BindingHelpers } from 'vuex-class/lib/bindings';

import {
  buildUsersByDepartmentsEmptyObject,
  User,
  UsersByDepartments,
  UsersSpecializations,
  UsersWithStatistic,
  UserWithoutProfile,
} from '@/store/models/User';
import {
  RiseUpLevel,
  SpecializationEnum,
  students,
  UserDepartment,
  UserRole,
} from '@/store/enums';
import { UserStatistic } from '@/store/models/UserStatistic';
import adapter from '@/store/adapter';
import Logger from '@/utils/Logger';
import { RiseUpProfile } from '@/../private_modules/dunice-space-shared/types';

export const MODULE_NAME = 'user';
export const getUserNamespace = (): BindingHelpers => namespace(MODULE_NAME);

interface GetUserListFilters {
  search?: string;
  level?: RiseUpLevel; // Level list
  roles?: UserRole[]; // Role list
  department?: UserDepartment; // Department list
}

@Module({ stateFactory: true, namespaced: true })
export default class UserModule extends VuexModule {
  private static _namespace: BindingHelpers = null;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getUserNamespace();

    return this._namespace;
  }

  public developer: RiseUpProfile = null;

  public usersByDepartmentsList: UsersByDepartments = buildUsersByDepartmentsEmptyObject();

  public usersWithStatistic: UsersWithStatistic[] = [];

  public techLeads: RiseUpProfile[] = null;

  public usersWithoutDepartment: UserWithoutProfile[] = [];

  @Mutation
  public setDeveloper(developer: RiseUpProfile): void {
    this.developer = developer;
  }

  @Mutation
  public setUsersByDepartmentsList(users: User[]): void {
    this.usersByDepartmentsList = users.reduce<UsersByDepartments>((acc: UsersByDepartments, user: User) => {
      for (const { slug } of user.departments) {
        const department = acc[slug] || [];
        department.push(user);
        acc[slug] = department;
      }
      return acc;
    }, buildUsersByDepartmentsEmptyObject());
  }

  @Mutation
  public setUsersWithStatistic(usersWithStatistic: UsersWithStatistic[]): void {
    this.usersWithStatistic = usersWithStatistic;
  }

  @Mutation
  public setTechLeads(techLeads: RiseUpProfile[]): void {
    this.techLeads = techLeads;
  }

  @Mutation
  public setUsersWithoutProfile(users: UserWithoutProfile[]): void {
    this.usersWithoutDepartment = users;
  }

  @Action
  public async getOne(userId: string): Promise<RiseUpProfile> {
    try {
      const response = await adapter.get<RiseUpProfile>(`/users/${userId}`, {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      const user = response.data;
      this.context.commit('setDeveloper', user);
      return user;
    } catch (error) {
      Logger.error('Store::UserModule:getOne', error);
      return null;
    }
  }

  @Action
  public async getStatistic(userId: string): Promise<UserStatistic> {
    try {
      const response = await adapter.get<UserStatistic>(`/users/${userId}/statistic`, {
        headers: this.context.rootGetters['auth/accessHeader'],
      });

      return response.data;
    } catch (error) {
      Logger.error('Store::UserModule:getStatistic', error);
      return null;
    }
  }

  @Action
  public async getAllStatistic(period: { from?: string, to?: string} = {}): Promise<void> {
    try {
      const response = await adapter.get<User[]>('/users/statistic', {
        headers: this.context.rootGetters['auth/accessHeader'],
        params: period,
      });
      const users = response.data;
      this.context.commit('setUsersWithStatistic', users);
    } catch (error) {
      Logger.error('Store::UserModule:getAllStatistic', error);
    }
  }

  @Action
  public async getDevelopers(filters: GetUserListFilters): Promise<void> {
    try {
      const internalFilters: GetUserListFilters & { departments?: UserDepartment[] } = { ...filters, roles: students };
      if (internalFilters.department != null) {
        internalFilters.departments = [internalFilters.department];
        delete internalFilters.department;
      }
      const response = await adapter.get<User[]>('/users', {
        headers: this.context.rootGetters['auth/accessHeader'],
        params: internalFilters,
      });
      const users = response.data;
      this.context.commit('setUsersByDepartmentsList', users);
      return null;
    } catch (error) {
      Logger.error('Store::UserModule:getDevelopers', error);
      return null;
    }
  }

  @Action
  public async getList(filters: GetUserListFilters): Promise<User[]> {
    try {
      const response = await adapter.get<User[]>('/users', {
        headers: this.context.rootGetters['auth/accessHeader'],
        params: filters,
      });
      return response.data;
    } catch (error) {
      Logger.error('Store::UserModule:getList', error);
      return null;
    }
  }

  @Action
  public async updateNeedCongratulate(): Promise<User> {
    try {
      const response = await adapter.patch<User>(
        '/users/congratulated',
        {},
        { headers: this.context.rootGetters['auth/accessHeader'] },
      );
      return response.data;
    } catch (error) {
      Logger.error('Store::UserModule:updateNeedCongratulate', error);
      return null;
    }
  }

  @Action
  public async getTechLeads(): Promise<User[]> {
    try {
      const response = await adapter.get<User[]>('/examiners', {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      this.context.commit('setTechLeads', response.data);
      return response.data;
    } catch (error) {
      Logger.error('Store::UserModule:getTechLeads', error);
      return null;
    }
  }

  @Action
  public async getUsersWithoutProfile(): Promise<UserWithoutProfile[]> {
    try {
      const response = await adapter.get<UserWithoutProfile[]>('/users/no-profile', {
        headers: this.context.rootGetters['auth/accessHeader'],
      });
      this.context.commit('setUsersWithoutProfile', response.data);
      return response.data;
    } catch (error) {
      Logger.error('Store::UserModule:getUsersWithoutProfile', error);
      return null;
    }
  }

  @Action
  public async createProfile(
    { userId, specializationType }: { userId: string; specializationType: SpecializationEnum },
  ): Promise<UsersSpecializations> {
    try {
      const response = await adapter.post<UsersSpecializations>(
        `/users/${userId}/profile`,
        { specializationType },
        { headers: this.context.rootGetters['auth/accessHeader'] },
      );
      const updatedList = this.usersWithoutDepartment.filter((item) => item.id !== userId);
      this.context.commit('setUsersWithoutProfile', updatedList);
      return response.data;
    } catch (error) {
      Logger.error('Store::UserModule:createProfile', error);
      return null;
    }
  }
}
