














import {
  Component, Vue,
} from 'vue-property-decorator';

import { ExamModule } from '@/store/modules';
import PassingExamList from '@/components/PassingExamList/PassingExamList.vue';
import WeekSelect from '@/components/WeekSelect.vue';

@Component({
  components: { PassingExamList, WeekSelect },
})
export default class MyPassingExams extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'my-passing-exam-list-page';

  @ExamModule.namespace.State('exams')
  private exams: ExamModule['exams'];

  @ExamModule.namespace.Action('getMy')
  private getMyExams: ExamModule['getMy'];

  @ExamModule.namespace.State('currentExamsFilter')
  private currentExamsFilter: ExamModule['currentExamsFilter'];

  @ExamModule.namespace.Mutation('updateCurrentExamsWeekNumberFilter')
  private updateCurrentExamsWeekNumberFilter: ExamModule['updateCurrentExamsWeekNumberFilter'];

  private created(): void {
    this.getMyExams(this.currentExamsFilter.weekNumber);
  }

  private get weekNumber(): string {
    return this.currentExamsFilter.weekNumber;
  }

  private set weekNumber(week: string) {
    this.updateCurrentExamsWeekNumberFilter(week);
    this.getMyExams(this.currentExamsFilter.weekNumber);
    this.$router.push({
      name: 'PassingExams',
      query: { weekNumber: week == null ? 'All' : String(week) },
    });
  }
}
