
















import {
  Component, Model, Prop, Vue,
} from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';

export interface AppSelectOption<T = string> {
  value: T;
  label: string;
  icon?: string
}

@Component({
  components: { Multiselect },
})
export default class AppSelect extends Vue {
  @Prop()
  private value: AppSelectOption;

  @Model('change')
  private model: AppSelectOption;

  private onChange(event: AppSelectOption) {
    this.$emit('update:value', event);
    this.$emit('change', event);
  }
}
