



















import { Component, Vue } from 'vue-property-decorator';
import {
  AuthModule,
  ConfigModule,
  ExamModule,
  SpecializationModule,
} from '@/store/modules';
import { UserDepartment } from '@/store/enums';
import WeekSelect from '@/components/WeekSelect.vue';
import ExamInfoModal from '@/components/modals/ExamInfoModal.vue';
import DepartmentSelect, { DepartmentOptionEnum, departmentsOptions } from '@/components/DepartmentSelect.vue';
import UserWithLevel from '@/components/UserWithLevel.vue';
import AnalysisPivot from '@/routes/AnalysisPivot.vue';
import AnalysisSortSelect from '@/components/AnalysisSortSelect.vue';

@Component({
  components: {
    AnalysisSortSelect,
    AnalysisPivot,
    WeekSelect,
    DepartmentSelect,
    ExamInfoModal,
    UserWithLevel,
  },
})
export default class ExamsAnalysisPivotPage extends Vue {
  private blockName: string = 'exams-analysis-pivot-page';

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  @AuthModule.namespace.Getter('isAdminStaff')
  private isAdminStaff: AuthModule['isAdminStaff'];

  @ExamModule.namespace.Action('getExamAnalysis')
  private getExamAnalysis: ExamModule['getExamAnalysis'];

  @ExamModule.namespace.State('examAnalysis')
  private examAnalysis: ExamModule['examAnalysis'];

  @ExamModule.namespace.State('currentExamsFilter')
  private currentExamsFilter: ExamModule['currentExamsFilter'];

  @ExamModule.namespace.Mutation('updateCurrentExamsDepartmentFilter')
  private updateCurrentExamsDepartmentFilter: ExamModule['updateCurrentExamsDepartmentFilter'];

  @SpecializationModule.namespace.Getter('specializationName')
  private specializationName: SpecializationModule['specializationName'];

  @SpecializationModule.namespace.Getter('frontendDirections')
  private frontendDirections: SpecializationModule['frontendDirections'];

  @SpecializationModule.namespace.Getter('backendDirections')
  private backendDirections: SpecializationModule['backendDirections'];

  private get department() {
    const chosenDepartment = departmentsOptions.find(
      (departmentsOption) => departmentsOption.value === this.currentExamsFilter.departments[0],
    );
    return chosenDepartment?.id ?? DepartmentOptionEnum.all;
  }

  private set department(value: number) {
    let departments: UserDepartment[] = [];
    if (value !== DepartmentOptionEnum.all) {
      departments = [DepartmentOptionEnum[value] as UserDepartment];
    }
    this.updateCurrentExamsDepartmentFilter(departments);
    this.getExamAnalysis();
  }

  private get weeks(): string[][] {
    return this.examAnalysis.weeks.map((item) => item.toFixed(2).split('.'));
  }

  created(): void {
    this.getExamAnalysis();
  }
}
