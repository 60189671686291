




import { Component, Prop, Vue } from 'vue-property-decorator';

import { Competence } from '@/store/models/Competence';
import { SelectOption } from '@/components/Select/SelectOption';
import { SpecializationModule } from '@/store/modules';

const LEVEL_REGEXP = /\((.+\s.+)\)/g;
const NAME_REGEXP = new RegExp('(<.*>)', 'g');

@Component({
  components: {},
})
export default class CompetenceName extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'competence-name';

  @Prop()
  private competence!: Competence | string;

  @Prop({ default: false })
  private sanitize: boolean;

  @SpecializationModule.namespace.Getter('specializationName')
  private specializationName: SpecializationModule['specializationName'];

  @SpecializationModule.namespace.Getter('frontendDirections')
  private frontend: SpecializationModule['frontendDirections'];

  @SpecializationModule.namespace.Getter('backendDirections')
  private backend: SpecializationModule['backendDirections'];

  private get name() {
    if (typeof this.competence === 'string') return this.competenceName;

    if (this.competence == null) {
      console.error(new Error('Competence is null or undefined'));
      return 'Error';
    }

    const {
      passed, directionName, specializationId,
    } = this.competence;
    const lineName = this.specializationName(specializationId);
    if (passed && directionName != null && lineName != null && ['FrontEnd', 'BackEnd'].includes(lineName)) {
      return this.nameWithDirection;
    }

    return this.competenceName;
  }

  private get competenceName() {
    return this.sanitize ? this.sanitizedName : this.computedCompetenceName;
  }

  private get nameWithDirection() {
    if (typeof this.competence === 'object') {
      const {
        directionName, specializationId,
      } = this.competence;
      const lineName = this.specializationName(specializationId);

      const options = lineName === 'FrontEnd' ? this.frontend : this.backend;
      const option = options.find((item: SelectOption) => item.value.toLowerCase() === directionName.toLowerCase());
      if (option == null) return this.competenceName;
      if (this.competenceName.includes('level')) {
        return this.competenceName.replace(LEVEL_REGEXP, `(${option.label} - $1)`);
      }
      if (this.competenceName.includes('Framework') || this.competenceName.includes('Language')) {
        return `${this.competenceName} (${option.label})`;
      }

      return this.competenceName;
    }
    return this.competenceName;
  }

  private get sanitizedName() {
    return this.computedCompetenceName.replace(NAME_REGEXP, ' ');
  }

  private get computedCompetenceName() {
    return typeof this.competence === 'string'
      ? this.competence
      : this.competence.name;
  }
}
