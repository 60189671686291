




























import { Component, Prop, Vue } from 'vue-property-decorator';

import { Exam } from '@/store/models/Exam';
import PassingExamListItem from '@/components/PassingExamList/PassingExamListItem.vue';
import PassingExamListItemMobile from '@/components/PassingExamList/PassingExamListItemMobile.vue';
import { ConfigModule } from '@/store/modules';

@Component({
  components: { PassingExamListItem, PassingExamListItemMobile },
})
export default class PassingExamList extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'passing-exam-list';

  @Prop()
  private exams!: Exam[];

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];
}
