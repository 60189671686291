







import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';

@Component({
  components: {},
})
export default class UserUnavailability extends Vue {
  private get mods(): Modificator {
    return {
      row: this.type === 'row' ? '@' : '',
    };
  }

  private blockName: string = 'user-unavailability';

  @Prop()
  private from!: string

  @Prop()
  private to!: string

  @Prop()
  private comment!: string

  @Prop({ default: 'column' })
  private type: 'row' | 'column';

  private get time() {
    const from = DateTime.fromISO(this.from).setLocale('ru');
    const to = DateTime.fromISO(this.to).setLocale('ru');

    return `${from.toFormat('HH.mm ccc')} - ${to.toFormat('HH.mm ccc')}`;
  }
}
