import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { BindingHelpers } from 'vuex-class/lib/bindings';

import { Specialization } from '@/store/models/Specialization';
import { SelectOption } from '@/components/Select/SelectOption';
import adapter from '@/store/adapter';
import Logger from '@/utils/Logger';
import { RiseUpProfile } from '@/../private_modules/dunice-space-shared/types';

export const MODULE_NAME = 'specialization';
export const getSpecializationNamespace = (): BindingHelpers => namespace(MODULE_NAME);

export interface GetRiseProgressParams {
  user?: RiseUpProfile;
  level?: string;
}

@Module({ stateFactory: true, namespaced: true })
export default class SpecializationModule extends VuexModule {
  private static _namespace: BindingHelpers = null;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getSpecializationNamespace();

    return this._namespace;
  }

  public specializationList: Specialization[] = [];

  public readonly frontendDirectionsList = [
    {
      id: 1,
      label: 'Angular',
      value: 'angular',
      header: false,
      type: 'frontend',
      levels: ['simple', 'strong'],
    },
    {
      id: 2,
      label: 'React',
      value: 'react',
      header: false,
      type: 'frontend',
      levels: ['simple', 'strong'],
    },
    {
      id: 3,
      label: 'Vue',
      value: 'vue',
      header: false,
      type: 'frontend',
      levels: ['simple', 'strong'],
    },
    {
      id: 4,
      label: 'React Native',
      value: 'reactNative',
      header: false,
      type: 'frontend',
      levels: ['simple'],
    },
  ]

  private readonly backendDirectionsList = [
    {
      id: 1,
      label: 'Python',
      value: 'python',
      header: false,
      type: 'language',
    },
    {
      id: 2,
      label: 'PHP',
      value: 'php',
      header: false,
      type: 'language',
    },
    {
      id: 3,
      label: 'Node',
      value: 'node',
      header: false,
      type: 'language',
    },
    {
      id: 4,
      label: 'Ruby',
      value: 'ruby',
      header: false,
      type: 'language',
    },
    {
      id: 5,
      label: 'Nest.js',
      value: 'nestJs',
      header: false,
      type: 'framework',
    },
    {
      id: 6,
      label: 'Django & Django Rest',
      value: 'django',
      header: false,
      type: 'framework',
    },
    {
      id: 7,
      label: 'Sinatra',
      value: 'sinatra',
      header: false,
      type: 'framework',
    },
    {
      id: 8,
      label: 'Laravel',
      value: 'laravel',
      header: false,
      type: 'framework',
    },
    {
      id: 9,
      label: 'Ruby on Rails',
      value: 'ror',
      header: false,
      type: 'framework',
    },
    {
      id: 10,
      label: 'Golang',
      value: 'golang',
      header: false,
      type: 'language',
    },
    {
      id: 11,
      label: 'Gin',
      value: 'gin',
      header: false,
      type: 'framework',
    },
  ];

  public get specializationName(): (id: string) => string {
    return (id) => (this.specializationList.find((item) => item.id === id))?.name;
  }

  public get frontentDirectionsByCardName(): (cardName: string) => SelectOption[] {
    return (cardName) => {
      const isStrongLevel = cardName.includes('strong');
      if (!isStrongLevel) return this.frontendDirectionsList;
      return this.frontendDirectionsList.filter((item: SelectOption) => item.levels.includes('strong'));
    };
  }

  public get backendDirectionsByCardName(): (cardName: string) => SelectOption[] {
    return (cardName) => this.backendDirectionsList.filter((item) => item.type === cardName);
  }

  public get backendDirections(): SelectOption[] {
    return this.backendDirectionsList;
  }

  public get frontendDirections(): SelectOption[] {
    return this.frontendDirectionsList;
  }

  @Mutation
  public setSpecializationList(specializationList: Specialization[]): void {
    this.specializationList = specializationList;
  }

  @Action
  public async getRiseProgress(
    { user, level }: GetRiseProgressParams,
  ): Promise<Specialization[]> {
    try {
      const requestedUser = user ?? this.context.rootState.auth.currentUser;
      const requestedLevel = level ?? this.context.rootState.auth.currentUser.level;
      const response = await adapter.get<Specialization[]>('/specializations/with-competencies', {
        headers: this.context.rootGetters['auth/accessHeader'],
        params: { userId: requestedUser.id, level: requestedLevel },
      });
      const list = response.data;
      this.context.commit('setSpecializationList', list);
      return list;
    } catch (error) {
      Logger.error('Store::SpecializationModule:getRiseProgress', error);
      return null;
    }
  }

  // @Action
  // public async getOne(user: User = this.context.rootState.auth.currentUser): Promise<Specialization> {
  //   return frontEndLine;
  // }
}
