import Vue from 'vue';

import AppButton from './AppButton.vue';
import AppChip from './AppChip.vue';
import AppDatetimePicker from './AppDatetimePicker.vue';
import AppInput from './AppInput.vue';
import AppRadio from './AppRadio.vue';
import AppSelect from './AppSelect.vue';
import AppStatusIcon from './AppStatusIcon.vue';
import AppTabBar from './AppTabBar.vue';

Vue.component('AppButton', AppButton);
Vue.component('AppChip', AppChip);
Vue.component('AppDatetimePicker', AppDatetimePicker);
Vue.component('AppInput', AppInput);
Vue.component('AppRadio', AppRadio);
Vue.component('AppSelect', AppSelect);
Vue.component('AppStatusIcon', AppStatusIcon);
Vue.component('AppTabBar', AppTabBar);
