


























import { Component, Vue } from 'vue-property-decorator';

import { AuthModule, ExamModule } from '@/store/modules';
import { UserDepartment } from '@/store/enums';
import DepartmentSelect, { DepartmentOptionEnum, departmentsOptions } from '@/components/DepartmentSelect.vue';
import CurrentExamList from '@/components/CurrentExamList/CurrentExamList.vue';
import WeekSelect from '@/components/WeekSelect.vue';
import ExamAssigneeSelect from '@/components/ExamAssigneeSelect.vue';

@Component({
  components: {
    WeekSelect,
    CurrentExamList,
    DepartmentSelect,
    ExamAssigneeSelect,
  },
})
export default class ExamList extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'exam-list-by-week-page';

  @AuthModule.namespace.Getter('isHeadOfDepartment')
  private isHeadOfDepartment: AuthModule['isHeadOfDepartment'];

  @ExamModule.namespace.State('exams')
  private exams: ExamModule['exams'];

  @ExamModule.namespace.State('currentExamsFilter')
  private currentExamsFilter: ExamModule['currentExamsFilter'];

  @ExamModule.namespace.Mutation('updateCurrentExamsDepartmentFilter')
  private updateCurrentExamsDepartmentFilter: ExamModule['updateCurrentExamsDepartmentFilter'];

  @ExamModule.namespace.Mutation('updateCurrentExamsWeekNumberFilter')
  private updateCurrentExamsWeekNumberFilter: ExamModule['updateCurrentExamsWeekNumberFilter'];

  @ExamModule.namespace.Mutation('updateCurrentExamsAssigneeFilter')
  private updateCurrentExamsAssigneeFilter: ExamModule['updateCurrentExamsAssigneeFilter'];

  @ExamModule.namespace.Action('getAll')
  private getAll: ExamModule['getAll'];

  @AuthModule.namespace.Getter('isAdminStaff')
  private isAdminStaff: AuthModule['isAdminStaff'];

  created(): void {
    this.retrieveExams();
  }

  private get department() {
    const chosenDepartment = departmentsOptions.find(
      (departmentsOption) => departmentsOption.value === this.currentExamsFilter.departments[0],
    );
    return chosenDepartment?.id ?? DepartmentOptionEnum.all;
  }

  private set department(value: number) {
    let departments: UserDepartment[] = [];
    if (value !== DepartmentOptionEnum.all) {
      departments = [DepartmentOptionEnum[value] as UserDepartment];
    }
    this.updateCurrentExamsDepartmentFilter(departments);
    this.retrieveExams();
  }

  private get assignee(): string {
    return this.currentExamsFilter.assignee;
  }

  private set assignee(value: string) {
    this.updateCurrentExamsAssigneeFilter(value);
    this.retrieveExams();
  }

  private get weekNumber(): string {
    return this.currentExamsFilter.weekNumber;
  }

  private set weekNumber(week) {
    this.updateCurrentExamsWeekNumberFilter(week);
    this.retrieveExams();
    this.$router.push({
      name: 'ExamsByWeek',
      query: { weekNumber: week ?? 'All' },
    });
  }

  private retrieveExams(): void {
    this.getAll();
  }
}
