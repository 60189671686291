









































































import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { Exam, UserWithExams } from '@/store/models/Exam';
import WeekSelect from '@/components/WeekSelect.vue';
import ExamInfoModal from '@/components/modals/ExamInfoModal.vue';
import DepartmentSelect from '@/components/DepartmentSelect.vue';
import UserWithLevel from '@/components/UserWithLevel.vue';

@Component({
  components: {
    WeekSelect,
    DepartmentSelect,
    ExamInfoModal,
    UserWithLevel,
  },
})
export default class AnalysisPivotPage extends Vue {
  private blockName: string = 'analysis-pivot-page';

  private failedStatuses = ['failed', 'sick', 'rejected', 'timeOver'];

  @Prop()
  weeks: string[][];

  @Prop()
  departments: {
    [key: string]: UserWithExams[];
  };

  @Prop({ default: () => false })
  showDepartments: boolean;

  getDateTime(year: number, weekNumber: number): string {
    const dt = DateTime.local();
    return `${dt.set({ year, weekNumber, weekday: 1 }).toFormat('dd.MM.yyyy')} - `
      + `${dt.set({ year, weekNumber, weekday: 7 }).toFormat('dd.MM.yyyy')}`;
  }

  created(): void {
    this.onChangeExams();
  }

  @Watch('departments')
  onChangeExams(): void {
    this.$nextTick(() => {
      const el = this.$refs.table;
      if (el != null && el instanceof Node) {
        const { width } = el.getBoundingClientRect();
        const scrolledContainer = el.closest('.exams-analysis-pivot-page__body');
        scrolledContainer?.scrollTo({
          left: width,
          behavior: 'smooth',
        });
      }
    });
  }

  private openDetailModal(student: UserWithExams, exam: Partial<Exam>): void {
    const user = {
      id: student.id,
      firstName: student.firstName,
      lastName: student.lastName,
      level: student.level,
      userSpecialization: student.userSpecialization,
    };
    this.$modal.show('exam-info', { exam, user });
  }

  private get currentWeek(): number {
    return DateTime.local().weekNumber;
  }

  private getWeekDates(weekNumber: string): string {
    const date = DateTime.fromObject({ weekNumber: Number(weekNumber) });
    const start = date.startOf('week').toFormat('dd LLL');
    const end = date.endOf('week').toFormat('dd LLL');
    return `${start} - ${end}`;
  }
}
