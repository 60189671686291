
































import { Vue, Component, Prop } from 'vue-property-decorator';

import { AuthModule } from '@/store/modules';

@Component({
  components: { },
})
export default class Template extends Vue {
  @Prop({ default: () => ({}) }) private mods!: Modificator;

  private blockName: string = 'menu';

  @AuthModule.namespace.State('currentUser') private currentUser: AuthModule['currentUser'];

  @AuthModule.namespace.Getter('isStudent') private isStudent: AuthModule['isStudent'];
}
