import 'reflect-metadata';
import Vue from 'vue';
import 'vue-material-design-icons/styles.css';
import VModal from 'vue-js-modal';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VTooltip from 'v-tooltip';

import router from './routes';
import store from './store';
import './registerServiceWorker';
import './components/core';
import './utils/filters';
import './plugins';
import App from './App.vue';

Vue.use(VModal);
Vue.use(VTooltip);

Vue.config.productionTip = false;
const main = async () => {
  // There is we can do something very important.
  // Those things must finish before start rendering
  await store.dispatch('config/activateIsMobileWatcher');
  await store.dispatch('auth/whoAmI');
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
};

main();
