
























































import { Component, Prop, Vue } from 'vue-property-decorator';

import { ExaminersStatistic } from '@/store/models/Statistic';
import UserWithLevel from '@/components/UserWithLevel.vue';
import date from '@/utils/filters/date';

@Component({
  components: { UserWithLevel },
  filters: { date },
})
export default class ExaminersStatisticList extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'examiners-statistic-list';

  @Prop() private data: ExaminersStatistic[];
}
