










import {
  Component,
  Vue,
  Prop,
  Model,
} from 'vue-property-decorator';

import Select from '@/components/Select/Select.vue';
import { SpecializationModule } from '@/store/modules';

const LEVEL_REGEXP = /\((?<level>.*)\slevel\)/;

@Component({
  components: { Select },
})
export default class DirectionNameSelect extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'direction-name-select';

  @Prop()
  private type: 'backend' | 'frontend';

  @Prop()
  private competenceName: string;

  @SpecializationModule.namespace.Getter('backendDirections')
  private backendDirections: SpecializationModule['backendDirections'];

  @SpecializationModule.namespace.Getter('frontendDirections')
  private frontendDirections: SpecializationModule['frontendDirections'];

  private get options() {
    const firstItem = {
      id: 0,
      label: 'Укажите фреймворк',
      header: true,
    };
    if (this.type === 'frontend') {
      const { groups: { level } } = LEVEL_REGEXP.exec(this.competenceName);
      const frontend = this.frontendDirections.filter((item) => item.levels.includes(level));
      return [firstItem, ...frontend];
    }
    if (this.competenceName === 'language') firstItem.label = 'Укажите язык';
    const backend = this.backendDirections.filter((item) => item.type === this.competenceName);
    return [firstItem, ...backend];
  }

  @Model('change')
  private directionId: number;
}
