















import {
  Component, Vue,
} from 'vue-property-decorator';

import { ExamModule } from '@/store/modules';
import ExaminingExamList from '@/components/ExamingExamList/ExaminingExamList.vue';
import SetExamStatusModal from '@/components/modals/SetExamStatusModal.vue';
import WeekSelect from '@/components/WeekSelect.vue';

@Component({
  components: { SetExamStatusModal, ExaminingExamList, WeekSelect },
})
export default class MyExaminingExams extends Vue {
  private mods: Modificator = { };

  private blockName: string = 'my-examining-exam-list-page';

  @ExamModule.namespace.State('currentExamsFilter')
  private currentExamsFilter: ExamModule['currentExamsFilter'];

  @ExamModule.namespace.Mutation('updateCurrentExamsWeekNumberFilter')
  private updateCurrentExamsWeekNumberFilter: ExamModule['updateCurrentExamsWeekNumberFilter'];

  @ExamModule.namespace.State('exams')
  private exams: ExamModule['exams'];

  @ExamModule.namespace.Action('getMy')
  private getMyExams: ExamModule['getMy'];

  private created(): void {
    this.getMyExams(this.currentExamsFilter.weekNumber);
  }

  private get weekNumber(): string {
    return this.currentExamsFilter.weekNumber;
  }

  private set weekNumber(week: string) {
    this.updateCurrentExamsWeekNumberFilter(week);
    this.getMyExams(this.currentExamsFilter.weekNumber);
    this.$router.push({
      name: 'ExaminingExams',
      query: { weekNumber: week == null ? 'All' : String(week) },
    });
  }

  private retrieveExams(): void {
    this.getMyExams(this.currentExamsFilter.weekNumber);
  }
}
