



































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { DateTime, Interval, DateObjectUnits } from 'luxon';
import { UserUnavailabilityModule } from '@/store/modules';

import UnavailabilityTimePicker from './UnavailabilityTimePicker.vue';
import UnavailabilityDatePicker from './UnavailabilityDatePicker.vue';

export enum ExamType {
  ThisWeek,
  NextWeek,
}

export interface UnavailabilityProp {
  id?: string;
  from: Date;
  to: Date;
  comment: string;
}

@Component({
  components: {
    UnavailabilityTimePicker, UnavailabilityDatePicker,
  },
})
export default class Unavailability extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'unavailability';

  @Prop()
  private weekType: ExamType;

  @Prop()
  private unavailability: UnavailabilityProp;

  @Prop()
  private index: number;

  @Prop()
  private showDeleteButton: boolean;

  @UserUnavailabilityModule.namespace.Mutation('updateUnavailability')
  private updateUnavailability: UserUnavailabilityModule['updateUnavailability'];

  @UserUnavailabilityModule.namespace.Action('delete')
  private deleteUnavailability: UserUnavailabilityModule['delete'];

  private get date(): Date {
    return this.unavailability.from;
  }

  private set date(value: Date) {
    let fromHour;
    let fromMinute;
    let toHour;
    let toMinute;
    if (this.unavailability.from == null) {
      fromHour = 9;
      fromMinute = 0;
    } else {
      const parsedFrom = DateTime.fromJSDate(this.unavailability.from ?? new Date());
      fromHour = parsedFrom.hour;
      fromMinute = parsedFrom.minute;
    }
    if (this.unavailability.to == null) {
      toHour = fromHour;
      toMinute = 30;
    } else {
      const parsedTo = DateTime.fromJSDate(this.unavailability.to ?? new Date());
      toHour = parsedTo.hour;
      toMinute = parsedTo.minute;
    }

    this.updateUnavailability({
      from: DateTime.fromJSDate(value ?? new Date()).set({ hour: fromHour, minute: fromMinute }).toISO(),
      to: DateTime.fromJSDate(value ?? new Date()).set({ hour: toHour, minute: toMinute }).toISO(),
      index: this.index,
    });
  }

  private get comment(): string {
    return this.unavailability.comment;
  }

  private onCommentChange(value: string) {
    this.updateUnavailability({
      comment: value,
      index: this.index,
    });
  }

  private startCalendarDate: DateTime = this.setStartDate();

  private endCalendarDate: DateTime = this.setEndDate();

  @Watch('weekType')
  private updateStartEndDates() {
    this.startCalendarDate = this.setStartDate();
    this.endCalendarDate = this.setEndDate();

    const fromLuxon = DateTime.fromJSDate(this.unavailability.from);
    const endOfWeek = DateTime.local().endOf('week');
    if (
      (this.weekType === ExamType.ThisWeek && endOfWeek < fromLuxon)
      || (this.weekType === ExamType.NextWeek && endOfWeek >= fromLuxon)
    ) {
      const {
        day,
        year,
        month,
      } = endOfWeek < fromLuxon ? DateTime.local() : DateTime.local().plus({ week: 1 });
      const from = fromLuxon.set({
        day,
        month,
        year,
      });
      const to = DateTime.fromJSDate(this.unavailability.to).set({
        day,
        month,
        year,
      });
      this.updateUnavailability({
        from: from.toISO(),
        to: to.toISO(),
        index: this.index,
      });
    }
  }

  private isDateDisabled(date: Date) {
    const calendarDate = DateTime.fromJSDate(date);

    return !Interval.fromDateTimes(this.startCalendarDate, this.endCalendarDate).contains(calendarDate);
  }

  private setTime(date: Date, type: 'to' | 'from') {
    let newDate: string = null;
    if (date != null) {
      const {
        day,
        hour,
        minute,
        month,
        year,
      } = DateTime.fromJSDate(date);

      const luxonDate = this.weekType === ExamType.ThisWeek
        ? DateTime.local().startOf('week')
        : DateTime.local().plus({ week: 1 }).startOf('week');

      const set: DateObjectUnits = {
        hour,
        minute,
      };

      if (this.unavailability[type] != null) {
        set.day = day;
        set.month = month;
        set.year = year;
      } else if (type === 'to' && this.unavailability.from != null) {
        const fromLuxon = DateTime.fromJSDate(this.unavailability.from);
        set.day = fromLuxon.day;
        set.month = fromLuxon.month;
        set.year = fromLuxon.year;
      }
      newDate = luxonDate
        .set(set)
        .toISO();
    }
    this.updateUnavailability({
      [type]: newDate,
      index: this.index,
    });
  }

  private onChangeStartDate(date: Date): void {
    this.setTime(date, 'from');
  }

  private onChangeEndDate(date: Date): void {
    this.setTime(date, 'to');
  }

  private setStartDate(): DateTime {
    return this.weekType === ExamType.NextWeek
      ? DateTime.local().plus({ weeks: 1 }).startOf('week')
      : DateTime.local().startOf('week');
  }

  private setEndDate(): DateTime {
    return this.weekType === ExamType.NextWeek
      ? DateTime.local().plus({ weeks: 1 }).set({ weekday: 5 })
      : DateTime.local().set({ weekday: 5 });
  }

  private onDelete(): void {
    this.deleteUnavailability(this.index);
  }
}
