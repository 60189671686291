




import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Navbar extends Vue {
  private blockName: string = 'navbar';

  private navbarElementId: string = 'space-navbar';

  private mounted() {
    document.dispatchEvent(new CustomEvent('navbar-place-ready', { detail: this.navbarElementId }));
  }
}
