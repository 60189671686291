















import { Vue } from 'vue-property-decorator';

export default class BurgerMenuIcon extends Vue {
  private blockName: string = 'burger-menu-icon';
}
