






























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { User } from '@/store/models/User';
import clearTags from '@/utils/filters/clear-tags';
import ExpansionPanel from '@/components/ExpansionPanel.vue';
import AppChip from '@/components/core/AppChip.vue';

@Component({
  components: {
    ExpansionPanel,
    Chip: AppChip,
  },
  filters: { clearTags },
})
export default class TotalDeveloperStatisticCard extends Vue {
  @Prop({ default: () => ({}) }) private mods!: Modificator;

  @Prop({ default: {} })
  private user!: User;

  private blockName: string = 'total-developer-statistic-card';

  private get competencies(): string[] {
    return this.user.periodProgress.map(({ name }) => clearTags(name)).filter((r) => Boolean(r));
  }

  private get blocks(): string[] {
    return this.user.periodProgress.map(({ specializationName }) => specializationName).filter((r) => Boolean(r));
  }

  private get riseUp(): number {
    const prevScore = Math.trunc((this.user.progressScore.full - this.user.progressScore.period) / 5);
    const currentScore = Math.trunc(this.user.progressScore.full / 5);
    return currentScore - prevScore;
  }
}
