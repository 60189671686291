


































































































import {
  Vue, Component,
} from 'vue-property-decorator';
import { DateTime } from 'luxon';
import PlusIcon from 'vue-material-design-icons/Plus.vue';

import integer from '@/utils/filters/integer';
import {
  ConfigModule, ExamModule, SpecializationModule, UserUnavailabilityModule,
} from '@/store/modules';
import { Competence } from '@/store/models/Competence';
import UserWithLevel from '@/components/UserWithLevel.vue';
import UserUnavailabilityComp from '@/components/UserUnavailability.vue';
import CompetenceDuration from '@/components/CompetenceDuration.vue';
import CompetenceName from '@/components/CompetenceName.vue';
import UnavailabilityTimePicker from '@/components/unavailability/UnavailabilityTimePicker.vue';
import UnavailabilityDatePicker from '@/components/unavailability/UnavailabilityDatePicker.vue';
import Unavailability, { ExamType } from '@/components/unavailability/Unavailability.vue';
import Logger from '@/utils/Logger';
import { UserUnavailability } from '@/store/models/UserUnavailability';
import DirectionNameSelect from '@/components/DirectionNameSelect.vue';

const initialUnavailability: Partial<UserUnavailability> = {
  from: null,
  to: null,
  comment: null,
};

@Component({
  components: {
    DirectionNameSelect,
    Unavailability,
    UnavailabilityDatePicker,
    UnavailabilityTimePicker,
    CompetenceName,
    CompetenceDuration,
    UserUnavailability: UserUnavailabilityComp,
    UserWithLevel,
    PlusIcon,
  },
  filters: {
    integer,
  },
})
export default class TryExamModal extends Vue {
  private blockName: string = 'try-exam-modal';

  private directionId: number = 0;

  private get mods(): Modificator {
    return {
      mobile: this.isMobile ? '@' : '',
    };
  }

  private get modalWidth() {
    return this.isMobile ? '100%' : '60%';
  }

  @ConfigModule.namespace.State('isMobile')
  private isMobile: ConfigModule['isMobile'];

  @ExamModule.namespace.Action('create')
  private createExam: ExamModule['create'];

  @ExamModule.namespace.State('error')
  private examError: ExamModule['error'];

  @UserUnavailabilityModule.namespace.Action('bulkCreateOrUpdate')
  private bulkCreateOrUpdate: UserUnavailabilityModule['bulkCreateOrUpdate'];

  @SpecializationModule.namespace.Action('getRiseProgress')
  private refetchRiseUpProgress: SpecializationModule['getRiseProgress'];

  @UserUnavailabilityModule.namespace.Action('getAllByWeek')
  private getUnavailabilityFrames: UserUnavailabilityModule['getAllByWeek'];

  @UserUnavailabilityModule.namespace.Getter('unavailability')
  private unavailability: UserUnavailabilityModule['unavailability'];

  @UserUnavailabilityModule.namespace.Mutation('addUnavailability')
  private addUnavailability: UserUnavailabilityModule['addUnavailability'];

  @UserUnavailabilityModule.namespace.Mutation('setUserUnavailabilities')
  private setUserUnavailabilities: UserUnavailabilityModule['setUserUnavailabilities'];

  @SpecializationModule.namespace.Getter('specializationName')
  private specializationName: SpecializationModule['specializationName'];

  @SpecializationModule.namespace.Getter('backendDirections')
  private backendDirections: SpecializationModule['backendDirections'];

  @SpecializationModule.namespace.Getter('frontendDirections')
  private frontendDirections: SpecializationModule['frontendDirections'];

  private competence: Competence = null;

  private isCurrentWeekDisabled: boolean = DateTime.local().startOf('week').set({
    hour: 12,
    minute: 0,
  }) < DateTime.local();

  private weekOptions = [{
    id: 0,
    label: 'ТЕКУЩАЯ НЕДЕЛЯ',
    value: ExamType.ThisWeek,
    name: 'weekOptions',
    comment: '(Вы можете записаться до 12 ч понедельника)',
    disabled: this.isCurrentWeekDisabled,
  },
  {
    id: 1,
    label: 'СЛЕДУЮЩАЯ НЕДЕЛЯ',
    value: ExamType.NextWeek,
    name: 'weekOptions',
  }];

  private isCollapsed: boolean = true;

  private type: ExamType = ExamType.NextWeek;

  private get weekNumber() {
    return Number(this.type) === ExamType.ThisWeek
      ? Number(DateTime.local().toFormat('kkkk.WW'))
      : Number(DateTime.local().plus({ weeks: 1 }).toFormat('kkkk.WW'));
  }

  private get createExamError() {
    return Array.isArray(this.examError.create?.message)
      ? this.examError.create.message.join()
      : this.examError.create?.message;
  }

  private get specialization(): string {
    return this.specializationName(this.competence.specializationId).toLowerCase();
  }

  private get competenceName(): string {
    return this.competence.name.toLowerCase();
  }

  private get hasDirectionName(): boolean {
    const isFramework = this.competenceName.includes('framework');
    const isLanguage = this.competenceName.includes('language');
    return (isFramework || isLanguage) && ['frontend', 'backend'].includes(this.specialization);
  }

  private onAddUnavailability() {
    this.addUnavailability({ ...initialUnavailability });
  }

  private receiveData(event: { params: { competence: Competence }}) {
    this.competence = event.params.competence;
    const from = Number(this.type) === ExamType.ThisWeek
      ? DateTime.local().startOf('week')
      : DateTime.local().startOf('week').plus({ week: 1 });
    const to = from.plus({ week: 1 });
    this.getUnavailabilityFrames({ from: from.toISO(), to: to.toISO() });
  }

  private async onSave() {
    try {
      const filteredUnavailability = this.unavailability.reduce((data, unavailable) => {
        if (unavailable.from !== null && unavailable.to !== null) {
          data.push({
            from: unavailable.from.toISOString(),
            to: unavailable.to.toISOString(),
            comment: unavailable.comment,
            id: unavailable.id,
          });
        }
        return data;
      }, []);
      await this.bulkCreateOrUpdate(filteredUnavailability);

      let directionName;

      if (this.hasDirectionName) {
        let directions = this.frontendDirections;
        if (this.specialization === 'backend') directions = this.backendDirections;
        const direction = directions.find((item) => item.id === this.directionId);
        directionName = direction.value;
      }

      const createdExam = await this.createExam({
        competenceId: this.competence.id,
        weekNumber: this.weekNumber,
        directionName,
      });

      if (createdExam !== null) {
        await this.refetchRiseUpProgress({});
        this.$modal.show('exam-created-modal', { competence: this.competence });
        this.$modal.hide('try-exam');
      }
    } catch (error) {
      Logger.error('TryExamModal::onSave', error);
    }
  }

  private onCancel() {
    this.$modal.hide('try-exam');
  }

  private reset() {
    this.competence = null;
    this.type = ExamType.NextWeek;
    this.setUserUnavailabilities([{ ...initialUnavailability }]);
    this.isCollapsed = true;
    this.directionId = 0;
  }

  private get isSaveDisabled(): boolean {
    if (this.hasDirectionName && this.directionId === 0) return true;
    return this.unavailability.some((item) => item.from != null && item.to != null && item.from >= item.to);
  }
}
