



























import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class UnavailabilityTimePicker extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'unavailability-time-picker';

  private timePickerOptions = {
    start: '09:00',
    step: '00:30',
    end: '18:00',
  }

  @Prop()
  private from: Date;

  @Prop()
  private to: Date;

  private get isInvalid(): boolean {
    return this.from != null && this.to != null && this.from >= this.to;
  }
}
