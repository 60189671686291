















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
  filters: {},
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default class AppTabBar<TTabs extends any[]> extends Vue {
  private blockName: string = 'app-tab-bar';

  @Prop({ default: () => ({}) }) private mods!: Modificator;

  @Prop({ required: true })
  private tabs!: TTabs;

  @Prop({ required: true })
  private current!: TTabs[number];

  private switchTab(tabName: TTabs[number]) {
    this.$emit('changed', tabName);
  }
}
