import { Route, NavigationGuardNext } from 'vue-router';

import store from '@/store';
import { redirectToSSO } from '@/utils';

export default (to: Route, from: Route, next: NavigationGuardNext): void => {
  if (store.getters['auth/isAuthenticated']) {
    if (store.getters['auth/isManagementStaff']) next({ name: 'DeveloperList' });
    else if (store.getters['auth/isNotApproved']) next({ name: 'Waiting' });
    else next({ name: 'MyStatus' });
  } else redirectToSSO();
};
