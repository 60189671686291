















import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class UnavailabilityDatePicker extends Vue {
  private mods: Modificator = {};

  private blockName: string = 'unavailability-date-picker';

  @Prop()
  private date: Date;

  @Prop()
  private isDateDisabled: (date: Date) => boolean;
}
